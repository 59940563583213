<template>
  <div class="top-item">
    <img :src="blackArrowLeftImg" @click="onClickLeft" alt/>
    <p>{{ $t('detail') }}</p>
    <img :src="search" @click="searchBTN()">
  </div>
    <div class="category-list">
        <van-sidebar v-model="left_active" class="tab-bar" @change="changeTab">
            <van-sidebar-item
                class="tab-bar-item"
                v-for="(item, index) in categoryList"
                :title="item.name"
                :key="index"
                @click="clickSideItem"
            />
        </van-sidebar>

        <div class="category-content">
            <h3>{{ currentCategoryTitle }}</h3>
            <div class="c-g-box">
                <van-list
                    v-model:loading="loading"
                    :finished="finished"
                    v-model:error="error"
                    :loading-text="$t('loading')"
                    :finished-text="$t('load_finished')"
                    :error-text="$t('load_error')"
                    @load="getCategoryGoods"
                >
                    <van-grid :column-num="3">
                        <van-grid-item v-for="(item, index) in categoryGoodsList"
                                       :key="index" :text="item.name"
                                       @click="searchGoods(item.id, item.name)"
                        >
                            <div class="img-area">
                                <van-image
                                    :src="item.img"
                                />
                            </div>

                            <p>{{ item.name }}</p>
                        </van-grid-item>
                    </van-grid>
                </van-list>
            </div>
        </div>
    </div>
    <van-loading class="loading-box" type="spinner" v-show="loadingPage"/>
</template>

<script>
import {ref} from 'vue'
import { NavBar, Sidebar, SidebarItem } from 'vant';
import {category, categoryGoods} from "@/api";
import blackArrowLeftImg from '@/assets/img_icon/black-arrow-left.png';
import search from '@/assets/img_icon/search-bold.png';

export default {
    name: "CateGoryView",
    components: {
        [NavBar.name]: NavBar,
        [Sidebar.name]: Sidebar,
        [SidebarItem.name]: SidebarItem,
    },
    data() {
        return {
            blackArrowLeftImg,
            search,
            categoryList: [
                {
                    id: 0,
                    name: this.$t('all'),
                }
            ],
            categoryGoodsList: [],
            currentCategoryTitle: '',
            loadingPage: false,
            c_id: null,
        };
    },
    setup() {
        const middle_active = ref(0)
        const left_active = ref(0)
        const activeNames = ref('1')
        const tabDetail = ref(0)
        const changeTab = (index) => {
            tabDetail.value = index;
        };
        const lang = process.env.VUE_APP_LANGUAGE === 'vi_vn' ? 'vn' : '';
        const pageSize = process.env.VUE_APP_PAGE_SIZE ? process.env.VUE_APP_PAGE_SIZE : 15;
        const error = ref(false);
        const loading = ref(false);
        const finished = ref(false);
        const page = 1;

        return {
            left_active,
            middle_active,
            activeNames,
            changeTab,
            tabDetail,
            lang,
            page,
            pageSize,
            loading,
            error,
            finished,
        }
    },
    created() {
        this.getCategory();
    },
    methods: {
        searchGoods(id, name) {
            this.$router.push({
                path: `/search`,
                query: {
                    goodsId: id,
                    name: name
                }
            })
        },
        clickSideItem (index) {
            this.currentCategoryTitle = this.categoryList[index].name;
            this.c_id = this.categoryList[index].id;
            this.categoryGoodsList = [];
            this.page = 1;
            this.loading = true;
            this.finished = false;
            this.getCategoryGoods();
        },
        getCategoryGoods () {
            if (this.c_id !== null) {
                let param = {
                    c_id: this.c_id,
                    page: this.page,
                    per_page: this.pageSize,
                };
                categoryGoods(param).then(res => {
                    if (res.data.data.length > 0) {
                        this.categoryGoodsList.push(...res.data.data);
                    }

                    this.loading = false;
                    if (res.data.data.length < res.data.per_page) {
                        this.finished = true;
                        this.page = 1;
                    } else {
                        this.page += 1;
                    }

                }).catch((error) => {
                    console.log('error', error);
                    this.error = true;
                    this.loading = false;
                })
            }
        },
        getCategory () {
            this.loadingPage = true;
            let param = {
                lang: this.lang,
            };
            category(param).then(res => {
                if (res.data.length > 0) {
                    this.categoryList.push(...res.data);
                    this.currentCategoryTitle = this.categoryList[0].name;
                    this.c_id = this.categoryList[0].id;
                    this.getCategoryGoods();
                }
                this.loadingPage = false;
            }).catch((error) => {
                console.log('error', error);
                this.loadingPage = false;
            });
        },
        onClickLeft() {
            this.$router.back();
        },
        searchBTN() {
            this.$router.push({path: '/search'})
        }
    }
}
</script>

<style lang="scss">

.category-list .van-sidebar-item {
    .van-sidebar-item__text {
        color: #64636D !important;
    }
}

.category-list .van-sidebar-item--select {
    .van-sidebar-item__text {
        color: #101519 !important;
        font-size: 1rem;
        font-weight: bold;
    }
}

.van-nav-bar__title {
    font-weight: bolder !important;
    color: #101519 !important;
}

.van-icon-arrow-left:before {
    color: #101519;
}


.img-area .van-image {
    max-width: 20.47vw;
    max-height:20.47vw;
    margin: auto;

    .van-image__img {
        max-width: 20.47vw;
        max-height: 20.47vw;
        width: auto;
        height: auto;
    }
}

</style>

<style lang="scss" src="@/styles/category.scss" scoped>

</style>
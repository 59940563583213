export default {
    "index": "Index",
    "package": "Package",
    "me": "Me",
    "category": "Category",
    "search_product": "Search for your favorite prize",
    "all": "all",
    "buy": "Buy",
    "sell": "Sell",
    "account_detail_list": "Account details",
    "go_back": "Return",
    "balance": "Balance",
    "date_time": "Date",
    "operate": "Operation",
    "detail": "Detail",
    "loading": "Loading...",
    "load_finished": "No more",
    "load_error": "The request failed, click to reload",
    "operation_failure": "operation failed",
    "account_frozen": "Account freeze",
    "register_failure": "Registration failed",
    "please_fill_recharge_amount": "Please fill in the recharge amount",
    "exceeded_total_withdrawal_limit": "Today's total withdrawal limit has been exceeded",
    "invalid_goods": "There are no trading items",
    "can_not_buy_self_goods": "Your own product cannot be purchased",

    "search": "Search",
    "search_history": "History search",
    "search_hot": "Popular searches",
    "recharge": "Recharge",
    "hot": "Hot",
    "new": "New",
    "trade_deadline": "Trading Deadline",
    "trade_post_time": "Transaction release time",
    "buy_now": "Buy now",
    "buy_confirm": "Confirm purchase",
    "recharge_success_msg": "The transaction will be completed immediately after the payment is successful",
    "trade_close_msg": "If the transaction is closed/refund occurs, the time for the refund to arrive is",
    "hour": "Hour",
    "order_amount": "Order amount",
    "agree": "Agree",
    "next": "Next step",
    "user_service_agreement": "User Services Agreement",
    "coin_payment": "Gold coin payment",
    "coin_recharge": "Gold coin recharge",
    "currency_vietnam": "Vietnamese dong",
    "coin": "Coin",
    "recharge_now": "Top up now",
    "platform_rules": "Platform rules",
    "got_time": "Start time",
    "unpack": "Go to unboxing",
    "trading": "In transaction",
    "selected": "chosen",
    "piece": "pieces",
    "maximum_selection": "most selected",
    "select": "choose",
    "up_sell": "most sold",
    "select_product": "Please select the product to sell",
    "confirm_sale_information": "Confirm sale information",
    "estimated_total_transaction_price": "The expected total transaction price of all commodities in this exchange",
    "sale_quantity": "Quantity sold",
    "enter_sale_quantity": "Please enter quantity for sale",
    "waring_sell_num_text": "The quantity to be sold shall not be greater than the total quantity of the commodity",
    "sum": "total",
    "enter_total_expected_transaction_price": "Enter the total expected transaction price",
    "rule_description": "Rules",
    "validity_sale_information": "Sale Information Validity Period",
    "confirm_sale_price": "The transaction will be completed immediately after the buyer pays, please confirm the sale amount again before confirming the sale information",
    "service_charge_msg": "The payment service provider will charge {x}% of the transaction amount during the transaction as a payment service fee",
    "enter_correct_price": "Please enter the correct price",
    "confirm_sale_price_again": "The transaction will be completed immediately after the buyer pays, please confirm the sale amount repeatedly before confirming the sale",
    "repeat_confirm_sale_price": "Second confirmation of expected transaction price",
    "phone": "Phone number",
    "unbind": "unbound",
    "bind_phone": "绑定手机",
    "my_account": "My account",
    "withdraw": "Withdraw",
    "exchage_stone_coin": "Exchange for naughty stone",
    "stone_coin": "Naughty stone",
    "about_stone_coin": "About naughty stone",
    "my_sale": "My sale",
    "trade_record": "Transaction Record",
    "service_center": "Customer Service",
    "platform_protocol": "Platform agreement",
    "version": "Version",
    "log_out": "Sign out",
    "login": "Log in",
    "sign_in": "Sign in",
    "your_account": "Your account",
    "please_enter": "Please enter",
    "verification_code": "Verification code",
    "send_verification_code": "Send the verification code",
    "wait": "Wait",
    "second": "Second",
    "read_msg": "I have read and agree",
    "user_agreement": "User Agreement",
    "privacy": "Privacy Agreement",
    "modify_personal_information": "Edit personal information",
    "save": "Save",
    "sell_goods": "Sell goods",
    "buy_goods": "Buy goods",
    "bank_transfer": "Transfer bank card",
    "exchange_stone_coin": "Exchange naughty stone",
    "check": "Review",
    "refund": "Refund",
    "withdrawal_method": "Please choose a withdrawal method",
    "bank_transfer_method": "Bank card transfer",
    "bound": "Bound",
    "bind": "Bind",
    "transfer_bank_account": "Transfer to bank card account",
    "bank_card": "Bank card",
    "current_withdrawable_balance": "Current cash balance",
    "please_enter_amount": "Please enter the amount",
    "minimum": "Lowest",
    "attention": "Notice",
    "maximum_withdrawal_day": "Maximum cash withdrawal per day",
    "withdraw_remaining_amount_today": "Today's withdrawal balance",
    "bank_username": "Bank card account name",
    "bank_account": "Bank card number",
    "bank_name": "Bank name",
    "name": "Name",
    "account_number": "Card number",
    "bank": "Bank",
    "money_to_stone_coin": "Exchange gold coins for naughty stones",
    "irreversible": "Irreversible",
    "remind": "Remind",
    "irreversible_msg": "This exchange is an irreversible behavior. After the gold coins are exchanged for naughty stones, they cannot be exchanged for gold coins and cannot be withdrawn.",
    "explain": "Illustrate",
    "explain_1": "The exchange of gold coins for naughty stones is a one-way operation",
    "explain_2": "That is, gold coins can be exchanged for naughty stones",
    "explain_3": "Naughty stones cannot be exchanged for gold coins",
    "explain_4": "And naughty stone cannot be withdrawn",
    "current_exchange_quantity": "Currently convertible",
    "please_enter_stone_coin_quantity": "Please enter the number of naughty stones to be exchanged",
    "exchange_quantity_day": "Redeemable every day",
    "exchanged_current": "Currently redeemed",
    "times": "Second-rate",
    "go_to_magic_box": "Go to the naughty box",
    "my_order": "My Order",
    "selling": "for sale",
    "complete_transaction": "Transaction complete",
    "cancel_trade": "Cancel the deal",
    "service_work_time": "Customer service working hours",
    "service_vip": "Exclusive customer service",
    "service_common": "Regular customer service",
    "close": "Close",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "short_coin": "You don't have enough coins",
    "post_success": "Published successfully",
    "enter_confirm_price": "Please enter a second confirmation price",
    "different_price": "The second confirmation price does not match the first price",
    "withdrawal_channel_maintenance": "Withdrawal channel maintenance",
    "enter_correct_phone_number": "Please enter the correct phone number",
    "enter_phone_number": "Please enter the phone number",
    "enter_verification_code": "请输入验证码",
    "please_agree_user_agreement": "please enter verification code",
    "verification_code_expired": "Verification code has expired",
    "please_bind_bank_card": "Please bind the bank card first",
    "please_enter_withdrawal_amount": "Please enter the withdrawal amount",
    "withdrawal_amount_cannot_be_less": "Withdrawal amount cannot be less than",
    "withdrawal_amount_cannot_be_over":"Withdrawal amount cannot be greater than",
    "less_balance": "Your balance is insufficient",
    "withdrawal_day_only": "Can only withdraw cash every day",
    "please_enter_exchange_quantity": "Please enter the exchange amount",
    "exchange_amount_cannot_be_less": "The exchange amount cannot be less than",
    "exchange_day_only": "Can only be redeemed per day",
    "platform_name": "Junk center",
    "finally_price": "Buy now",
    "goods_quantity": "Number of Products",
    "edit_info": "Edit information",
    "success": "Success",
    "nickname":"Nickname",
    "agent_recharge":"Agent recharge",
    "system_gift":"System gift",
    "recharge_method":"Payment method",
    "please_login_again":"Please login again",
    "what_is_naughty_stone":"what is naughty stone？",
    "go_to_magic_box_title":"Naughty box",
    "go_to_magic_box_content":"The magic box can't be opened completely, and the surprises are constantly opened.",
    "pc_alert":"Please use a mobile device to open the page",
    "choose_product":"Select product",
    "recharge_channel_close":"The recharge channel is under maintenance",
    "withdraw_channel_close":"Withdrawal channel maintenance",
    "exchange_channel_close":"Exchange channel maintenance",
    "choose_bank":"Please select a bank",
    "invalid_recharge_channel":"Payment channel does not exist",
    "recharge_failed":"Payment failed",
    "complete": "Completed",
    "failure": "Fail",
    "sold": "This item has been sold",
    "bought": "Bought",
    "account_detail": "Account detail",
    "exist_withdrawal": "Withdrawal exist",
    "withdrawal_refund": "Cancellation and refund of withdrawal",
    "manual_deduction": "Manual deduction",
    "refuse": "Refuse",
    "exchange_refund": "Exchange refund",
    "arrival_time": "The amount will be arrived within Five minutes to 24 hours",
    "waring": "Waring",
    "waring_price_text": "price must be equal to or greater than 1",
    "new_guide": "Novice guide",
}
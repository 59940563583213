<template>
    <div class="order-view" >
        <div class="top-item">
            <img :src="blackArrow"
                 @click="onClickLeft" alt/>
            <p class="top-title">{{ $t('my_order') }}</p>
        </div>
        <van-tabs v-model:active="tab" swipeable class="tab-box" @click-tab="changeTab">
            <van-tab name="1" :title="$t('selling')" :style="{backgroundColor: 'transparent'}">
                <OrderList ref="indexList1"
                           :page="page" :sort="sort" :tab="tab"
                           :finished="finished"
                           @setPage="setPage"
                           @setFinished="setFinished"
                >
                </OrderList>
            </van-tab>
            <van-tab name="2" :title="$t('complete_transaction')" :style="{backgroundColor: 'transparent'}">
                <OrderList ref="indexList2"
                           :page="page" :sort="sort"
                           :tab="tab" :finished="finished"
                           @setPage="setPage"
                           @setFinished="setFinished"
                >
                </OrderList>
            </van-tab>
        </van-tabs>
        <FooterView/>
    </div>
</template>

<script lang="js">
import FooterView from "@/layout/footer/FooterView";
import { Tab, Tabs} from "vant";
import {ref} from "vue";
import OrderList from "@/components/OrderList";
import sortDefaultImg from '@/assets/img_icon/sort_default_2x.png';
import sortAscImg from '@/assets/img_icon/sort_asc_2x.png';
import sortDescImg from '@/assets/img_icon/sort_desc_2x.png';
import blackArrow from '@/assets/img_icon/black-arrow-left.png';

export default {
    name: "OrderView",
    components: {
        OrderList,
        FooterView: FooterView,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
    },
    data() {
        return {
            sortDefaultImg,
            sortAscImg,
            sortDescImg,
            blackArrow,
            hotImg: sortDefaultImg,
            newImg: sortDefaultImg,
            isHotActive: false,
            isNewActive: false,
            tab: '1',
        }
    },
    mounted() {
        this.tab = this.$route.params.tab;
    },
    setup() {
        const sort = ref(0);
        const page = ref(1);
        const finished = ref(false);
        return {
            page,
            finished,
            sort,
        }
    },
    methods: {
        onClickLeft(){
            this.$router.back();
        },
        setFinished (valve) {
            this.finished = valve;
        },
        setPage (valve) {
            this.page = valve;
        },
        changeTab () {
            this.listEmpty();
            this.finished = false;
            this.page = 1;
        },
        listEmpty () {
            switch (this.tab) {
                case '1':
                    this.$refs.indexList1.setListEmpty();
                    break;
                case '2':
                    this.$refs.indexList2.setListEmpty();
                    break;
            }
        },

    },
}
</script>

<style lang="scss" src="@/styles/order.scss"  scoped>
</style>
export default {
    "index": "ទំព័រដើម",
    "package": "កាបូប",
    "me": "របស់ខ្ញុំ",
    "category": "ចំណាត់ថ្នាក់",
    "search_product": "ស្វែងរករង្វាន់ដែលអ្នកពេញចិត្ត",
    "all": "ទាំងអស់",
    "buy": "ទិញ",
    "sell": "លក់",
    "account_detail_list": "ពត៌មានលម្អិតនៃគណនី",
    "go_back": "ត្រលប់មកវិញ",
    "balance": "សមតុល្យ",
    "date_time": "ពេលវេលា",
    "operate": "ដំណើរការ",
    "detail": "ពត៌មានលម្អិត",
    "loading": "កំពុងផ្ទុក",
    "load_finished": "ការផ្ទុកបានបញ្ចប់",
    "load_error": "សំណើបានបរាជ័យ សូមចុចដើម្បីផ្ទុកឡើងវិញ",
    "operation_failure": "ប្រតិបត្តិការបានបរាជ័យ",
    "account_frozen": "គណនីត្រូវបានផ្អាក",
    "register_failure": "ការចុះឈ្មោះបានបរាជ័យ",
    "please_fill_recharge_amount": "សូមបំពេញចំនួនទឹកប្រាក់ដែលអ្នកចង់បញ្ចូល",
    "exceeded_total_withdrawal_limit": "ចំនួននៃការដកប្រាក់នៅថ្ងៃនេះ បានប្រើអស់ហើយ",
    "invalid_goods": "ផលិតផលដោះដូរមិនត្រឹមត្រូវទេ",
    "can_not_buy_self_goods": "មិនអាចទិញផលិតផលរបស់ខ្លួនឯងបានទេ",

    "search": "ស្វែងរក",
    "search_history": "ប្រវត្តិនៃការស្វែងរក",
    "search_hot": "ការស្វែងរកដែលពេញនិយម",
    "recharge": "បញ្ចូលទឹកប្រាក់",
    "hot": "ពេញនិយម",
    "new": "ថ្មីៗ",
    "trade_deadline": "ថ្ងៃផុតកំណត់នៃការដោះដូរ",
    "trade_post_time": "ពេញវេលានៃការដោះដូរ",
    "buy_now": "ទិញឥឡូវនេះ",
    "buy_confirm": "បញ្ជាក់ការទិញ",
    "recharge_success_msg": "ប្រតិបត្តិការនឹងបញ្ចប់ភ្លាមៗ នៅពេលការទិញបានជោគជ័យ",
    "trade_close_msg": "ប្រសិនបើប្រតិបត្តិការត្រូវបានបិទ/ធ្វើសំណង ពេលវេលាសម្រាប់ការបង្វិលសងគឺ",
    "hour": "ម៉ោង",
    "order_amount": "ចំនួនដែលទិញ",
    "agree": "យល់ព្រម",
    "next": "បន្ទាប់",
    "user_service_agreement": "កិច្ចព្រមព្រៀងសេវាកម្មអ្នកប្រើប្រាស់",
    "coin_payment": "ទូរទាត់កាក់មាស",
    "coin_recharge": "បញ្ចូលកាក់មាស",
    "currency_vietnam": "ដុង",
    "coin": "កាក់មាស",
    "recharge_now": "បញ្ចូលកាក់មាសឥឡូវនេះ",
    "platform_rules": "ច្បាប់វេទិកា",
    "got_time": "ពេលវេលាដើម្បីទិញ",
    "unpack": "ទៅកាន់ការបើកប្រអប់",
    "trading": "ការដោះដូរ",
    "selected": "បានជ្រើសរើស",
    "piece": "បំណែក",
    "maximum_selection": "ចំនួនជ្រើសរើសសរុប",
    "select": "ជ្រើសរើស",
    "up_sell": "លក់ដាច់ជាងគេ",
    "select_product": "សូមជ្រើសរើសផលិតផលដែលអ្នកចង់លក់",
    "confirm_sale_information": "បញ្ជាក់ព័ត៌មានលក់",
    "estimated_total_transaction_price": "តម្លៃប្រតិបត្តិការសរុបដែលរំពឹងទុកនៃទំនិញទាំងអស់នៅក្នុងការលក់នេះ។",
    "sum": "សរុប",
    "enter_total_expected_transaction_price": "បញ្ចូលតម្លៃប្រតិបត្តិការដែលរំពឹងទុកសរុប",
    "rule_description": "ច្បាប់",
    "validity_sale_information": "សុពលភាពអំពីព័ត៌មាននៃការលក់",
    "confirm_sale_price": "ប្រតិបត្តិការនឹងត្រូវបញ្ចប់ភ្លាមៗបន្ទាប់ពីអ្នកទិញបង់ប្រាក់ សូមបញ្ជាក់ចំនួនលក់ម្តងទៀត មុននឹងបញ្ជាក់ព័ត៌មានលក់។",
    "service_charge_msg": "អ្នកផ្តល់សេវាទូទាត់នឹងគិតថ្លៃចំនួន {x}% នៃចំនួនប្រតិបត្តិការជាថ្លៃសេវាទូទាត់ក្នុងអំឡុងពេលដំណើរការប្រតិបត្តិការ",
    "enter_correct_price": "សូមបញ្ចូលតម្លៃត្រឹមត្រូវ",
    "confirm_sale_price_again": "ប្រតិបត្តិការនឹងត្រូវបញ្ចប់ភ្លាមៗបន្ទាប់ពីអ្នកទិញបង់ប្រាក់ សូមបញ្ជាក់ចំនួនលក់ម្តងហើយម្តងទៀតមុនពេលបញ្ជាក់ការលក់។",
    "repeat_confirm_sale_price": "ការបញ្ជាក់ទីពីរនៃតម្លៃប្រតិបត្តិការដែលរំពឹងទុក",
    "phone": "លេខទូរស័ព្ទដៃ",
    "unbind": "មិនចង",
    "bind_phone": "ចងទូរស័ព្ទដៃ",
    "my_account": "គណនីរបស់ខ្ញុំ",
    "withdraw": "ដក",
    "exchage_stone_coin": "ដូរ​យកកាក់ថ្ម",
    "stone_coin": "ថ្ម​អាក្រក់",
   "about_stone_coin": "អំពីកាក់ថ្ម",
   "my_sale": "ការលក់របស់ខ្ញុំ",
   "trade_record": "កំណត់ត្រាដោះដូរ",
   "service_center": "មជ្ឈមណ្ឌលសេវាកម្មអតិថិជន",
    "platform_protocol": "កិច្ចព្រមព្រៀង",
    "version": "លេខកំណែ",
    "log_out": "ចេញ",
    "login": "ចូល",
    "sign_in": "ចុះឈ្មោះ",
    "your_account": "គណនីរបស់អ្នក",
    "please_enter": "សូមបញ្ចូល",
    "verification_code": "លេខកូដផ្ទៀងផ្ទាត់",
    "send_verification_code": "ផ្ញើលេខកូដផ្ទៀងផ្ទាត់",
    "wait": "រង់ចាំ",
    "second": "ទីពីរ",
    "read_msg": "ខ្ញុំបានអាន និងយល់ព្រម",
    "user_agreement": "កិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់",
    "privacy": "隐私协议",
    "modify_personal_information": "កែប្រែព័ត៌មានផ្ទាល់ខ្លួន",
    "save": "រក្សាទុក",
    "sell_goods": "ទំនិញសម្រាប់លក់",
    "buy_goods": "ទិញទំនិញ",
    "bank_transfer": "ផ្ទេរទៅកាតធនាគារ",
    "exchange_stone_coin": "ដូរ​យកកាក់​ថ្ម​",
    "check": "ពិនិត្យឡើងវិញ",
    "refund": "សំណង",
    "withdrawal_method": "សូមជ្រើសរើសវិធីដកប្រាក់",
    "bank_transfer_method": "ការផ្ទេរទៅកាតធនាគារ",
    "bound": "ចងភ្ជាប់",
    "bind": "កំពុងចង",
    "transfer_bank_account": "ផ្ទេរទៅគណនីធនាគារ",
    "bank_card": "កាតធនាគារ",
    "current_withdrawable_balance": "សមតុល្យដែលអាចដកបានឥឡុវនេះ",
    "please_enter_amount": "សូមបញ្ចូលចំនួនទឹកប្រាក់",
    "minimum": "ទាបបំផុត",
    "attention": "សេចក្តីជូនដំណឹង",
    "maximum_withdrawal_day": "ការដកប្រាក់អតិបរមាក្នុងមួយថ្ងៃ",
    "withdraw_remaining_amount_today": "ចំនួនដែលនៅសល់ដែលត្រូវដកថ្ងៃនេះ",
    "bank_username": "ឈ្មោះបើកគណនីកាតធនាគារ",
    "bank_account": "លេខកាតធនាគារ",
    "bank_name": "ឈ្មោះធនាគារដែលគណនីត្រូវបានបើក",
    "name": "ឈ្មោះ",
    "account_number": "លេខកាត",
    "bank": "ធនាគារ",
    "money_to_stone_coin": "ប្តូរកាក់មាសសម្រាប់កាក់ថ្ម",
    "irreversible": "មិនអាចត្រឡប់វិញបាន",
    "remind": "រំលឹក",
    "irreversible_msg": "ការផ្លាស់ប្តូរនេះគឺមិនអាចត្រឡប់វិញបានទេ នៅពេលដែលកាក់មាសត្រូវបានផ្លាស់ប្តូរសម្រាប់កាក់ថ្មហើយ អ្នកមិនអាចប្តូរជាកាក់មាស ឬដកបានទេ។",
    "explain": "បង្ហាញ",
    "explain_1": "ការផ្លាស់ប្តូរកាក់មាសសម្រាប់កាក់ថ្ម គឺជាប្រតិបត្តិការមួយផ្លូវ",
    "explain_2": "នោះគឺ, កាក់មាសអាចដោះដូរជាកាក់ថ្ម",
    "explain_3": "កាក់ថ្មមិនអាចដូរជាកាក់មាសបានទេ។",
    "explain_4": "ហើយកាក់ថ្មមិនអាចដកចេញបានទេ។",
    "current_exchange_quantity": "អាចដោះដូរយកបានឥឡូវនេះ",
    "please_enter_stone_coin_quantity": "សូមបញ្ចូលចំនួនកាក់ថ្មដើម្បីដោះដូរ",
    "exchange_quantity_day": "អាចដោះដូរបានជារៀងរាល់ថ្ងៃ",
    "exchanged_current": "ត្រូវបានដោះដូររួច",
    "times": "អត្រាទីពីរ",
    "go_to_magic_box": "ចូលទៅកាន់ប្រអប់វេទមន្ត",
    "my_order": "ការបញ្ជាទិញរបស់ខ្ញុំ",
    "selling": "សម្រាប់លក់",
    "complete_transaction": "ប្រតិបត្តិការបានបញ្ចប់",
    "cancel_trade": "បោះបង់ប្រតិបត្តិការ",
    "service_work_time": "ម៉ោងធ្វើការរបស់សេវាកម្មអតិថិជន",
    "service_vip": "សេវាកម្មអតិថិជនVIP",
    "service_common": "សេវាកម្មអតិថិជនទូទៅ",
    "close": "បិទ",
    "confirm": "បញ្ជាក់",
    "cancel": "បោះបង់",
    "short_coin": "អ្នកមិនមានកាក់មាសគ្រប់គ្រាន់ទេ",
    "post_success": "បានបង្ហោះដោយជោគជ័យ",
    "enter_confirm_price": "សូមបញ្ចូលតម្លៃបញ្ជាក់លើកទីពីរ",
    "different_price": "តម្លៃបញ្ជាក់លើកទីពីរមិនត្រូវគ្នានឹងតម្លៃទីមួយទេ។",
    "withdrawal_channel_maintenance": "បណ្តាញដកប្រាក់កំពុងស្ថិតក្រោមការថែទាំ",
    "enter_correct_phone_number": "សូមបញ្ចូលលេខទូរស័ព្ទអោយបានត្រឹមត្រូវ",
    "enter_phone_number": "សូមបញ្ចូលលេខទូរស័ព្ទ",
    "enter_verification_code": "សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់",
    "please_agree_user_agreement": "សូមយល់ព្រមលើកិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់ជាមុនសិន",
    "verification_code_expired": "លេខកូដផ្ទៀងផ្ទាត់បានផុតកំណត់ហើយ",
    "please_bind_bank_card": "សូមចងកាតធនាគាររបស់អ្នកជាមុនសិន",
    "please_enter_withdrawal_amount": "សូមបញ្ចូលចំនួនទឹកប្រាក់ដែលអ្នកចង់ដក",
    "withdrawal_amount_cannot_be_less": "ចំនួននៃការដកប្រាក់មិនអាចតិចជាង",
    "withdrawal_amount_cannot_be_over":"ចំនួននៃការដកប្រាក់មិនអាចច្រើនជាង",
    "less_balance": "សមតុល្យរបស់អ្នកមិនគ្រប់គ្រាន់ទេ",
    "withdrawal_day_only": "អ្នកអាចដកប្រាក់ជារៀងរាល់ថ្ងៃបានតែ",
    "please_enter_exchange_quantity": "សូមបញ្ចូលបរិមាណនៃការដោះដូរ",
    "exchange_amount_cannot_be_less": "ចំនួននៃការដោះដូរមិនអាចតិចជាង",
    "exchange_day_only": "អាចដោះដូរជារៀងរាល់ថ្ងៃបានតែ",
    "platform_name": "មជ្ឈមណ្ឌលដោះដូរទំនិញជជុះ",
    "finally_price": "តម្លៃចុងក្រោយ",
    "goods_quantity": "បរិមាណផលិតផល",
    "edit_info": "កែពត៌មាន",
    "success": "ជោគជ័យ",
    "nickname":"ឈ្មោះហៅក្រៅ",
    "agent_recharge":"បញ្ចូលទឹកប្រាក់តាមភ្នាក់ងារ",
    "system_gift":"អំណោយពីប្រព័ន្ធ",
    "recharge_method":"វិធីបង់ប្រាក់",
    "please_login_again":"សូមចូលម្តងទៀត",
    "what_is_naughty_stone":"តើអ្វីទៅជាកាក់ថ្ម?",
    "go_to_magic_box_title":"ប្រអប់វេទមន្ត",
    "go_to_magic_box_content":"ប្រអប់វេទមន្តគឺគ្មានទីបញ្ចប់ហើយមានការភ្ញាក់ផ្អើលគ្មានទីបញ្ចប់។。",
    "pc_alert":"សូមប្រើទូរស័ព្ទដៃដើម្បីបើកគេហទំព័រ",
    "choose_product":"ជ្រើសរើសផលិតផល",
    "recharge_channel_close":"បណ្ដាញបញ្ចូលទឹកប្រាក់គឺស្ថិតនៅក្រោមការថែទាំ",
    "withdraw_channel_close":"បណ្តាញដកប្រាក់កំពុងស្ថិតក្រោមការថែទាំ",
    "exchange_channel_close":"បណ្តាញផ្លាស់ប្តូរកំពុងស្ថិតក្រោមការថែទាំ",
    "choose_bank":"សូមជ្រើសរើសធនាគារ",
    "invalid_recharge_channel":"បណ្តាញបង់ប្រាក់មិនត្រឹមត្រូវទេ",
    "recharge_failed":"ការទូទាត់បានបរាជ័យ",
    "complete": "បានបញ្ចប់",
    "failure": "បរាជ័យ",
    "sold": "បានលក់",
    "bought": "បានទិញ",
    "account_detail": "ព័ត៌មានលម្អិតអំពីការផ្លាស់ប្តូរគណនី",
    "exist_withdrawal": "មានកម្មវិធីដកប្រាក់ដែលកំពុងពិនិត្យ",
    "withdrawal_refund": "សំណង សម្រាប់ការដកប្រាក់មិនជោគជ័យ",
    "manual_deduction": "ការកាត់ដោយប្រព័ន្ធ",
    "refuse": "បដិសេធ",
    "exchange_refund": "សំណងវិញ ប្រសិនបើការដោះដូរបរាជ័យ",
    "arrival_time": "ទទួលបានក្នុងរយៈពេល 5 នាទី ទៅ 24 ម៉ោង។",
    "waring": "ព្រមាន",
    "waring_price_text": "តម្លៃត្រូវតែស្មើនឹង ឬធំជាង 1",
    "new_guide": "ការណែនាំសម្រាប់អ្នកថ្មី",
}
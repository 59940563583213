
import {login, thirdLogin} from "../../api/account";
import {getToken, removeToken, setToken} from "../../utils/auth";
const state = {
    token: getToken(),
    id: 0,
    name: '',
    avatar: '',
    phone: '',
}

const mutations = {
    SET_ID: (state, id) => {
        state.id = id
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
}

const actions = {
    // user login
    login({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo).then(response => {
                const { data } = response
                commit('SET_TOKEN', data.token)
                commit('SET_ID', data.user_id)
                setToken(data.token)
                if (localStorage.getItem(`first_time_login_${data.user_id}`) == null) {
                    localStorage.setItem(`first_time_login_${data.user_id}`, data.user_id);
                }
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user login
    thirdLogin({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            thirdLogin(userInfo).then(response => {
                const { data } = response
                commit('SET_TOKEN', data.token)
                commit('SET_ID', data.user_id)
                setToken(data.token)
                if (localStorage.getItem(`first_time_login_${data.user_id}`) == null) {
                    localStorage.setItem(`first_time_login_${data.user_id}`, data.user_id);
                }
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({ commit }) {
        return new Promise((resolve) => {
            commit('SET_TOKEN', '')
            removeToken()

            // reset visited views and cached views
            // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
            // dispatch('tagsView/delAllViews', null, { root: true })
            resolve()
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}

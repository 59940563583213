<template>
    <div class="parent-div">
        <div class="login-view">
            <van-button class="back-btn" @click="onClickLeft">
                <img :src="arrowLeft" alt/>
            </van-button>
            <p class="text">{{ $t('login') }}</p>
            <p class="text">{{ $t('your_account') }}</p>
        </div>
        <div class="form-login">
            <div class="aselect" >
                <div class="selector" @click="toggle()">
                    <div class="label">
                        <img class="flag-icon" :src="defaultLangObj.flag" />
                        <span >{{ defaultLangObj.name }}</span>
                    </div>
<!--                    <span>{{ value }}</span>-->
                    <div class="arrow" :class="{     expanded : visible }"></div>
                    <div :class="{ hidden : !visible, visible }">
                        <ul>
                            <li v-for="(item,index) in lang" @click="select(item)" v-bind:key="index">
                                <img class="flag-icon" :src="item.flag"> {{ item.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <van-form @submit="onSubmit(preNum, phoneNumber, code)">
                <p class="label-field" :style="{color: (isPhoneEmpty === false) ? 'Black' : 'red'}">
                    {{ $t('phone') }}
                </p>
                <div class="PhoneField">
                    <van-button v-model="preNum" class="pre-num" @click="showPicker = true">{{ preNum }}</van-button>
                    <van-field
                        class="num-input"
                        v-model="phoneNumber"
                        type="digit"
                        :placeholder="$t('enter_phone_number')"
                        @update:model-value="inputPhone"
                        center
                        clearable
                    >
                    </van-field>
                </div>

                <p class="label-field" :style="{color: (isCodeEmpty === false) ? 'Black' : 'red'}">
                    {{ $t('verification_code') }}
                </p>
                <van-field
                    class="CodeField"
                    v-model="code"
                    type="digit"
                    :placeholder="$t('enter_verification_code')"
                    maxlength="6"
                    center
                    @update:model-value="inputCode"
                >
                    <template #button>
                        <van-button :disabled="finishedTimer === true" class="countdown" @click="start">
                            <van-count-down
                                ref="countDown"
                                millisecond
                                :time="time"
                                :auto-start="false"
                                format="ss"
                                @finish="onFinish"
                                v-show="finishedTimer === true"
                            >
                                <template #default="timeData">
                                    <span>{{ $t('wait') }} {{ timeData.seconds }} {{ $t('second') }}</span>
                                </template>
                            </van-count-down>
                            <span class="send-code" v-show="finishedTimer === false">{{
                                    $t('send_verification_code')
                                }}</span>
                        </van-button>
                    </template>
                </van-field>
                <van-checkbox
                    class="agree-check-box"
                    v-model="isChecked"
                    label-disabled
                    icon-size="16px"
                    :checked-color="checkedColor"
                >
                    {{ $t('agree') }}
                    ( <router-link to="/useragreement">{{ $t('user_agreement') }}</router-link> )
                    ( <router-link to="/privacy">{{ $t('privacy') }}</router-link> )
                </van-checkbox>
                <div class="div-submit-btn">
                    <van-button
                        class="submit-btn"
                        :disabled="(isChecked === false || phoneNumber === '' || code === '')"
                        :style="{borderRadius: '12px',fontSize: '1.2rem', color: '#ffffff' ,backgroundColor: (isChecked === false || phoneNumber === '' || code === '') ? '#AEAEAF' :'#D93D23' , width: '90vw', height: '15vw'}"
                        block
                        native-type="submit"
                        loading-type="spinner"
                        :loading="loading"
                    >
                        {{ $t('next') }}
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
    <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
            v-model="preNum"
            title="Areacode"
            :confirm-button-text="$t('confirm')"
            :cancel-button-text="$t('cancel')"
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
        />
    </van-popup>
    <van-overlay :show="shadowShow">
        <van-loading class="loading-box" type="spinner"/>
    </van-overlay>
</template>
<script>

import {ref} from 'vue';
import arrowLeft from '@/assets/img_icon/black-arrow-left.png';
import {lang} from '@/const/const'

export default {
    name: "LoginView",
    data () {
        return {
            lang: lang,
            visible: false,
            shadowShow: false,
            arrowLeft,
            checkedColor: process.env.VUE_APP_CHECKEDBGCOLOR ? process.env.VUE_APP_CHECKEDBGCOLOR : '',
        };
    },
    setup() {
        const phoneNumber = ref('');
        const preNum = ref('+86');
        const code = ref('');
        const isChecked = ref(false);
        const isRequired = ref(false);
        const finishedTimer = ref(false);
        const countDown = ref(null);
        const isPhoneEmpty = ref(false);
        const isCodeEmpty = ref(false);
        const time = ref(60000);
        const loading = ref(false);
        const langCode = ref('');
        const defaultLangObj = ref({});
        const onFinish = () => {
            finishedTimer.value = false;
        };
        const inputPhone = () => {
            if (phoneNumber.value !== '') {
                isPhoneEmpty.value = false;
            }  else {
                isPhoneEmpty.value = true;
            }
        };
        const inputCode = () => {
            if (code.value !== '') {
                isCodeEmpty.value = false;
            } else {
                isCodeEmpty.value = true;
            }
        };
        const columns = [
            { text: '+86', value: '+86' },
        ];
        const result = ref('');
        const showPicker = ref(false);
        const onConfirm = (value) => {
            showPicker.value = false;
            preNum.value = value.value;
        };

        let url = window.location.href;
        let urlInfo = new URLSearchParams(url.split('?')[1]);
        const accessToken = urlInfo.get('access_token');
        return {
            preNum,
            phoneNumber,
            code,
            isChecked,
            isRequired,
            countDown,
            time,
            finishedTimer,
            onFinish,
            isPhoneEmpty,
            isCodeEmpty,
            inputPhone,
            inputCode,
            showPicker,
            columns,
            result,
            onConfirm,
            loading,
            accessToken,
            defaultLangObj,
            langCode,
        }
    },
    created() {
        if (localStorage.getItem('locale')) {
            this.defaultLangObj.name = localStorage.getItem('locale');
            for (let i=1; i<=Object.keys(lang).length; i++) {
                if(localStorage.getItem('locale') === lang[i].name) {
                    this.defaultLangObj = lang[i];
                    if(this.defaultLangObj.name === 'vn'){
                        this.$i18n.locale = 'vi_vn';
                    } else if (this.defaultLangObj.name === 'en') {
                        this.$i18n.locale = 'en_us'
                    } else if (this.defaultLangObj.name === 'kh') {
                        this.$i18n.locale = 'kh_km';
                    } else {
                        this.$i18n.locale = 'zh_cn'
                    }
                }
            }
        } else {
            // this.defaultLangObj = lang[1]
            for (let i=1; i<=Object.keys(lang).length; i++) {
                if(process.env.VUE_APP_LANGUAGE === lang[i].code) {
                    this.defaultLangObj = lang[i];
                }
            }
        }
        this.thirdLogin();
    },
    methods: {
        start () {
            if (this.phoneNumber === '') {
                this.isPhoneEmpty = true;
            } else {
                this.finishedTimer = true;
                this.countDown.start();
            }
        },
        thirdLogin () {
            // 如果有传 accessToken 就视为 三方登录
            if (this.accessToken) {
                this.shadowShow = true;
                let param = {
                    access_token: this.accessToken,
                };
                this.$store.dispatch('user/thirdLogin', param)
                    .then(() => {
                        this.shadowShow = false;
                        this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
                    })
                    .catch((e) => {
                        this.shadowShow = false;
                        console.log(e);
                    })
            }
        },
        onClickLeft() {
            this.$router.back();
        },
        onSubmit(preNum, phone, code) {
            // return false;
            this.loading = true;
            // if (phone.substring(0, 1) === '0') {
            //     const phoneNum = phone.split('0')[1];
            //     phone = phoneNum;
            // }
            let param = {
                area_code: preNum,
                phone: phone,
                code: code
            };
            this.$store.dispatch('user/login', param)
                .then(() => {
                    this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
                    this.shadowShow = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        chooseLang() {
            console.log(this.langCode);
        },
        toggle() {
            this.visible = !this.visible;
        },
        select(option) {
            this.defaultLangObj = option;
            if(option.name === 'vn'){
                this.$i18n.locale = 'vi_vn';
                localStorage.setItem('locale', option.name);
            } else if (option.name === 'en') {
                this.$i18n.locale = 'en_us'
                localStorage.setItem('locale', option.name);
            } else if (option.name === 'kh') {
                this.$i18n.locale = 'kh_km'
                localStorage.setItem('locale', option.name);
            } else {
                this.$i18n.locale = 'zh_cn'
                localStorage.setItem('locale', option.name);
            }
        }
    }
}
</script>

<style lang="scss" src="../styles/loginView.scss" scoped></style>
<template>
    <div class="billing-record-view">
        <div class="top-info-record">
            <BackNavBar :title="$t('account_detail_list')"></BackNavBar>
            <p class="surplus">{{ $t('balance') }}</p>
            <p class="balance">{{ coin }}</p>
        </div>
        <div class="record-box">
            <van-list
                v-model:loading="loading"
                :finished="finished"
                v-model:error="error"
                :loading-text="$t('loading')"
                :finished-text="$t('load_finished')"
                :error-text="$t('load_error')"
                @load="onLoad"
            >
                <div class="list-record-info" v-for="(record) in billingRecord" :key="record">
                    <div class="record-list">
                        <div class="main-record">
                            <p class="record-name" v-show="record.type === 1">
                                {{ $t('sell_goods') }}
                            </p>
                            <p class="record-name" v-show="record.type === 2">
                                {{ $t('buy_goods') }}
                            </p>
                            <p class="record-name" v-show="record.type === 3">
                                {{ $t('bank_transfer') }}({{ statusInfo[record.status] }})
                            </p>
                            <p class="record-name" v-show="record.type === 4">
                                {{ $t('exchange_stone_coin') }}({{ statusInfo[record.status] }})
                            </p>
                            <p class="record-name" v-show="record.type === 5">
                                {{ $t('recharge') }}
                            </p>
                            <p class="record-name" v-show="record.type === 6">
                                {{ $t('agent_recharge') }}
                            </p>
                            <p class="record-name" v-show="record.type === 7">
                                {{ $t('system_gift') }}
                            </p>
                            <p class="record-name" v-show="record.type === 8">
                                {{ $t('withdrawal_refund') }}
                            </p>
                            <p class="record-name" v-show="record.type === 9">
                                {{ $t('manual_deduction') }}
                            </p>
                            <p class="record-name" v-show="record.type === 10">
                                {{ $t('exchange_refund') }}
                            </p>
                            <p class="record-name" v-show="record.type === 11">
                                {{ $t('manual_deduction') }}
                            </p>
                            <p class="record-balance" :style="{color: (record.action  === 1) ? '#101519' : '#D93D23'}">
                                <span v-show="record.action  === 1">+</span>
                                <span v-show="record.action  === 2">-</span>
                                {{ record.change_money }}
                            </p>
                        </div>
                        <div class="sub-record">
                            <p class="record-date">
                                {{ record.created_at }}
                            </p>
                            <p class="record-total-balance">
                                {{ $t('balance') }} {{ record.after_money }}
                            </p>
                        </div>
                    </div>
                    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', margin: '0' }"/>

                </div>
            </van-list>
        </div>
    </div>
</template>
<script>

import {ref} from 'vue';
import {billingRecord} from "@/api/user";
import blackArrowLeftImg from '@/assets/img_icon/black-arrow-left.png';
import BackNavBar from "@/components/BackNavBar";

export default {
    name: "BillingRecords",
    components: {
        BackNavBar,
    },
    data() {
        return {
            blackArrowLeftImg,
            statusInfo: {
                0: this.$t('failure'),
                1: this.$t('check'),
                2: this.$t('complete'),
                3: this.$t('refund'),
                4: this.$t('failure'),
                5: this.$t('refuse'),
                6: this.$t('complete'),
                7: this.$t('check'),
            },
        };
    },
    setup() {
        const billingRecord = ref([]);
        const noRecord = ref(false);
        const coin = ref(0);
        const pageSize = process.env.VUE_APP_PAGE_SIZE ? process.env.VUE_APP_PAGE_SIZE : 15;
        const error = ref(false);
        const loading = ref(false);
        const finished = ref(false);
        const page = 1;

        return {
            page,
            pageSize,
            loading,
            error,
            finished,
            coin,
            billingRecord,
            noRecord
        };
    },
    methods: {
        onLoad () {
            let param = {
                page: this.page,
                per_page: this.pageSize,
            };
            billingRecord(param).then(res => {
                if (res.data.data.length > 0) {
                    this.coin = res.data.coin;
                    this.billingRecord.push(...res.data.data);
                } else {
                    this.noRecord = true;
                }
                this.loading = false;
                if (res.data.data.length < res.data.per_page) {
                    this.finished = true;
                    this.page = 1;
                } else {
                    this.page += 1;
                }
            }).catch((error) => {
                console.log('error', error);
                this.error = true;
                this.loading = false;
            })
        },
        onClickLeft() {
            this.$router.back();
        }
    }
}
</script>

<style lang="scss" src="@/styles/billingRecord.scss" scoped>
</style>

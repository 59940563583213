<template>
  <div class="top-item">
      <img :src="blackArrowLeftImg" @click="onClickLeft" alt/>
      <p>{{ $t('coin_recharge') }}</p>
  </div>
    <div class="recharge-page">
        <div class="wrapper">
            <div class="container">
                <div
                    v-for="(item, index) in recharge_amount"
                    :key="index"
                    :class="(amountActive === index ) ? 'item-active' : 'item'"
                    @click="amountClick(index, item)"
                    v-show="loading === false"
                >
                    <p :class="(amountActive === index ) ? 'amountActive' : 'amount'">
                        <span :class="(amountActive === index ) ? 'currencyActive' : 'currency'">{{ currency }}</span>
                        {{ item.value }}
                    </p>
                    <div :class="(amountActive === index ) ? 'bottom-item-active' : 'bottom-item'">
                        <p :class="(amountActive === index ) ? 'tien-vang-active' : 'tien-vang'">{{ item.to_coin }} {{
                                $t('coin')
                            }}</p>
                    </div>
                </div>
            </div>
        </div>
        <p class="pay-bank-title">{{ $t('recharge_method') }}</p>
        <div class="pay-bank-list">
            <van-radio-group v-model="checked">
                <van-cell-group inset>
                    <van-cell
                        style="height: 8vh"
                        v-for="(item, index) in pay_method"
                        clickable
                        :key="index"
                        @click="checked = index"
                    >
                          <span style="display: flex;">
                            <van-image class="pay-method-image" :src="item.image"/>
                            <p class="pay-method-name">{{ item.name }}</p>
                          </span>
                        <template #right-icon>
                            <van-radio
                                :checked-color="checkedColor"
                                :name="index"
                            />
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-radio-group>
        </div>
        <van-button
            :class="amountActive === false || checked === false ? 'submit-bank-disabled' : 'submit-bank'"
            :loading="loadingRecharge"
            @click="submitPay"
        >
            <p>{{ $t('recharge_now') }}</p>
        </van-button>
        <van-loading class="loading-box" type="spinner" v-show="loading"/>
    </div>
</template>

<script>
import {ref} from 'vue';
import {rechargeMethod, recharge} from "@/api";
import blackArrowLeftImg from '@/assets/img_icon/black-arrow-left.png';

export default {
    name: "RechargeView",
    data () {
        return {
            blackArrowLeftImg,
            checkedColor: process.env.VUE_APP_CHECKEDBGCOLOR ? process.env.VUE_APP_CHECKEDBGCOLOR : '',
        };
    },
    setup() {
        const currency = process.env.VUE_APP_CURRENCY_SYMBOL ? process.env.VUE_APP_CURRENCY_SYMBOL : '';
        const recharge_amount = ref([]);
        const amountActive = ref(false);
        const pay_method = ref([]);
        const loading = ref(true);
        const loadingRecharge = ref(false);
        const checked = ref(false);
        const amount = ref('0');
        const amountClick = (index, data) => {
            amountActive.value = index;
            amount.value = data.value;
        };
        return {
            currency,
            recharge_amount,
            pay_method,
            loading,
            loadingRecharge,
            checked,
            amountClick,
            amountActive,
            amount,
        };
    },
    mounted() {
        rechargeMethod().then(res => {
            if (res.msg === 'success') {
                this.pay_method = res.data.pay_method;
                this.recharge_amount = res.data.recharge_amount;
                this.loading = false;
            }
        }).catch((error) => {
            console.log('error', error);
            this.error = true;
        });
    },
    methods: {
        onClickLeft() {
            this.$router.back();
        },
        submitPay () {
            if (this.amountActive === false || this.checked === false) {
                return false;
            }
            this.loadingRecharge = true;
            let pay_id = this.pay_method[this.checked].id;
            let param = {
                pay_id: pay_id,
                money: this.amount,
            };
            recharge(param).then(res => {
                if (res.msg === 'success') {
                    this.loadingRecharge = false;
                    window.open(res.data.url, "_blank");
                }
            }).catch((error) => {
                console.log('error', error);
                this.loadingRecharge = false;
            });
        },
    }
}
</script>
<style lang="scss" src="@/styles/recharge.scss" scoped></style>

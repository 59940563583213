import i18n from '@/lang/index';

export const ErrorMsg = {
    10003: i18n.global.t('bound'),
    10004: i18n.global.t('operation_failure'),
    10005: i18n.global.t('account_frozen'),
    10006: i18n.global.t('register_failure'),
    10008: i18n.global.t('register_failure'),
    10009: i18n.global.t('recharge_channel_close'),
    10010: i18n.global.t('withdraw_channel_close'),
    10011: i18n.global.t('exchange_channel_close'),
    20001: i18n.global.t('please_fill_recharge_amount'),
    20002: i18n.global.t('short_coin'),
    20003: i18n.global.t('exchange_amount_cannot_be_less'),
    20005: i18n.global.t('exchange_day_only'),
    20006: i18n.global.t('please_bind_bank_card'),
    20007: i18n.global.t('withdrawal_amount_cannot_be_less'),
    20008: i18n.global.t('withdrawal_amount_cannot_be_over'),
    20009: i18n.global.t('exceeded_total_withdrawal_limit'),
    20010: i18n.global.t('withdrawal_day_only'),
    20011: i18n.global.t('exist_withdrawal'),
    30001: i18n.global.t('invalid_goods'),
    30002: i18n.global.t('can_not_buy_self_goods'),
    50001: i18n.global.t('invalid_recharge_channel'),
    50002: i18n.global.t('recharge_failed'),
}

export const lang = {
    1: {
        name: 'kh',
        flag: require('@/assets/img_icon/Flag-Cambodia.png'),
        code: 'kh_km'
    },
    2: {
        name: 'cn',
        flag: require('@/assets/img_icon/Flag-China.png'),
        code: 'zh_cn'
    },
    3: {
        name: 'en',
        flag: require('@/assets/img_icon/Flag-UK.png'),
        code: 'en_us'
    },
    4: {
        name: 'vn',
        flag: require('@/assets/img_icon/Flag-Vietnam.png'),
        code: 'vi_vn'
    },
}
<template>
    <div class="search-box">
        <div class="search-form-box">
            <form action="/">
                <van-search style="height: 5vh;"
                            v-model="searchItem"
                            :placeholder="$t('search_product')"
                            @search="onSearch"
                            @cancel="back"
                            show-action
                            autofocus
                            :action-text="$t('cancel')"
                />
            </form>
        </div>
        <!--  default page-->
        <div class="list-box" v-show="defaultPageSearch === true">
            <!--    recently search-->
            <div class="history-box" v-show="hotSearchList.length > 0">
                <div class="history">
                    <h4>{{ $t('search_history') }}</h4>
                    <van-button class="clear-btn" size="mini" @click="clearSearchHistory">
                        <img width="18" :src="Delete" height="18" alt="">
                    </van-button>
                </div>
                <div>
                    <van-grid clickable>
                        <van-grid-item v-for="(item, index) in localHistoryList"  v-bind:key="index" :text="item"
                        @click="searchHistory(item)"
                        />
                    </van-grid>
                </div>
            </div>
            <!--    hot search   -->
            <div class="hot-box" v-show="hotSearchList.length > 0">
                <div class="hot-text">
                    <h4>{{ $t('search_hot') }}</h4>
                </div>
                <div>
                    <van-grid clickable>
                        <van-grid-item v-for="(item, index) in hotSearchList"  v-bind:key="index" :text="item.content"
                                       @click="searchHistory(item.content)"
                        />
                    </van-grid>
                </div>
            </div>
        </div>
        <!--  search page with data-->
        <div style="margin-top: 1vh;" v-show="defaultPageSearch === false">
            <!--    filter-->
            <div class="sort-box">
                <span :class="[isHotActive ? 'sort-hot-active' : 'sort-hot']" @click="indexSort(1)">
                    <p>{{ $t('hot') }}</p>
                    <img :src="hotImg">
                </span>
                    <span :class="[isNewActive ? 'sort-new-active' : 'sort-new']" @click="indexSort(2)">
                    <p>{{ $t('new') }}</p>
                    <img :src="newImg">
                </span>
            </div>
            <!--    data-->
            <div >
                <IndexList ref="indexList1"
                           :page="page" :sort="sort" :name="name" :goodsId="goodsId" :type="1"
                           :adShow="adShow" :finished="finished"
                           @setAdShow="setAdShow"
                           @setPage="setPage"
                           @setFinished="setFinished"
                >
                </IndexList>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import {Search} from "vant";
import {ref} from "vue";
import '@vant/touch-emulator';
import Delete from '@/assets/img_icon/delete_2x.png';
import {hotSearch} from "@/api";
import adDefaultImg from "@/assets/img_icon/freshippo.png";
import sortDefaultImg from "@/assets/img_icon/sort_default_2x.png";
import sortAscImg from "@/assets/img_icon/sort_asc_2x.png";
import sortDescImg from "@/assets/img_icon/sort_desc_2x.png";
import IndexList from "@/components/IndexList";

export default {
    name: "SearchPage",
    components: {
        IndexList,
        [Search.name]: Search,
    },
    data() {
        return {
            Delete,
            hotSearchList: [],
            searchList: [],
            adDefaultImg,
            sortDefaultImg,
            sortAscImg,
            sortDescImg,
            hotImg: sortDefaultImg,
            newImg: sortDefaultImg,
            isHotActive: false,
            isNewActive: false,
            localHistoryList: [],
            name: '',
            goodsId: null,
            finished: true,
        };
    },
    setup() {
        const searchHistoryKey = 'search_history';
        const defaultPageSearch = ref(true);
        const searchItem = ref('');
        const sort = ref(0);
        const adShow = ref(false);
        const page = ref(1);
        return {
            defaultPageSearch,
            searchItem,
            searchHistoryKey,
            page,
            sort,
            adShow,
        }
    },
    created() {
        if (this.$route.query.goodsId) {
            this.goodsId = this.$route.query.goodsId;
            this.searchItem = this.$route.query.name;
            this.page = 1;
            this.finished = false;
            this.defaultPageSearch = false;
        }
        this.getLocalStorage();
        this.getHotSearch();
    },
    methods: {
        setName (valve) {
            this.name = valve;
        },
        setAdShow () {
            this.adShow = false;
        },
        setFinished (valve) {
            this.finished = valve;
        },
        setPage (valve) {
            this.page = valve;
        },
        getHotSearch () {
            hotSearch({}).then(res => {
                if (res.data && res.data.length > 0) {
                    this.hotSearchList = res.data;
                }

            }).catch((error) => {
                console.log('error', error);
            });
        },
        back() {
            this.$router.back();
        },
        searchHistory (valve) {
            this.searchItem = valve;
            this.onSearch(valve);
        },
        onSearch (valve) {
            valve = valve.trim();
            this.setLocalStorage(valve);
            this.name = valve;
            this.page = 1;
            this.finished = false;
            this.defaultPageSearch = false;
        },
        getLocalStorage () {
            let localHistory = localStorage.getItem(this.searchHistoryKey);
            if (localHistory) {
                this.localHistoryList = JSON.parse(localHistory);
            }
        },
        setLocalStorage (valve) {
            if (valve === undefined || valve === '') {
                return ;
            }
            let data = [];
            // 获取本地缓存
            let localHistory = localStorage.getItem(this.searchHistoryKey);
            if (localHistory) {
                localHistory = JSON.parse(localHistory);
                if (!localHistory.includes(valve)) {
                    localHistory.push(valve);
                }
                data = localHistory;
            } else {
                data.push(valve);
            }

            // 存入本地缓存
            localStorage.setItem(this.searchHistoryKey, JSON.stringify(data));
        },
        clearSearchHistory () {
            localStorage.removeItem(this.searchHistoryKey);
            this.localHistoryList = [];
        },
        indexSort (type) {
            if (type === 1) {
                switch (this.hotImg) {
                    case sortDefaultImg:
                        this.hotImg = this.sortDescImg;
                        this.isHotActive = true;
                        this.sort = 1;
                        break;
                    case sortDescImg:
                        this.hotImg = this.sortAscImg;
                        this.isHotActive = true;
                        this.sort = 2;
                        break;
                    case sortAscImg:
                        this.hotImg = this.sortDefaultImg;
                        this.isHotActive = false;
                        this.sort = 0;
                        break;
                }
                this.isNewActive = false;
                this.newImg = this.sortDefaultImg;
            } else {
                switch (this.newImg) {
                    case sortDefaultImg:
                        this.newImg = this.sortDescImg;
                        this.isNewActive = true;
                        this.sort = 3;
                        break;
                    case sortDescImg:
                        this.newImg = this.sortAscImg;
                        this.isNewActive = true;
                        this.sort = 4;
                        break;
                    case sortAscImg:
                        this.newImg = this.sortDefaultImg;
                        this.isNewActive = false;
                        this.sort = 5;
                        break;
                }
                this.isHotActive = false;
                this.HotImg = this.sortDefaultImg;
            }

            this.$refs.indexList1.setListEmpty();
            this.adShow = false;
            this.page = 1;
            this.finished = false;
        },
    }
}
</script>

<style lang="scss" src="@/styles/search.scss" scoped>
</style>
<template>
    <van-overlay :show="loadingUserinfo"
                 z-index="2"
                 style="background: rgba(143, 143, 143, 0.7);"
                 @click="loadingUserinfo = false"
    >
        <div class="wrapper"
             @click.stop>
            <van-loading type="spinner" color="#535353"/>
        </div>
    </van-overlay>
    <div class="info">
        <div class="bound-btn" v-show="isBound === false" @click="bind">
            <img class="bound-icon" :src="phoneImg" alt/>
            <span class="bound-text">{{ $t('bind_phone') }}</span>
            <img class="bound-icon" :src="redArrowRightImg" alt/>
        </div>
        <div class="top-info">
            <div class="user-info">
                <div class="left-info">
                    <van-skeleton avatar :row="1" :loading="loadingUserinfo">
                        <img class="profile-picture"
                             :src="userData.avatar"/>
                    </van-skeleton>
                </div>
                <div class="right-info">
                    <ul class="list-info">
                        <li class="nickname">{{ userData.nickname }}</li>
                        <li class="phone"> {{ userData.phone }}</li>
                    </ul>
                </div>
            </div>
            <div class="edit-btn">
                <van-button class="button" @click="goTOEdit(userData.avatar, userData.nickname)">
                    <span>
                        <img :src="editImg" alt/>
                        <p>{{ $t('edit_info') }}</p>
                    </span>
                </van-button>
            </div>
        </div>
        <div class="card-info-1">
            <div class="top-text">
                <p class="my-account">{{ $t('my_account') }}</p>
                <router-link class="top-left-red-text" to="/billingrecords">{{ $t('account_detail') }}</router-link>
            </div>
            <p class="exchange-currency">1 {{ $t('coin') }} =
                {{ exchangeRate }}{{ currency }}</p>
            <div class="balance">
                <router-link class="router-link" to="/billingrecords">
                    <p class="user-balance">{{ userData.coin }}</p>
                    <img class="coin-icon" :src="coinImg" alt/>
                    <p class="gold-money">{{ $t('coin') }}</p>
                </router-link>
                <van-button class="bank-btn" v-show="switch_withdraw == 1" @click="showWithdrawalMethod">
                    {{ $t('withdraw') }}
                </van-button>
            </div>
            <van-divider class="card-1-divider"/>

            <span class="stone-rate">
                <p class="exchange-rate">1 {{ $t('coin') }} = {{ userData.coin_to_stone }} {{ userData.stone_coin }}</p>
                <router-link to="/aboutnaughtystone">
                    <img class="info-img" :src="informationImg" alt/>
                </router-link>
            </span>
            <van-button v-show="switch_exchange == 1" class="btn-card-info-1" @click="showReminderExchange = true">
                {{ $t('exchage_stone_coin') }}
            </van-button>
        </div>
        <div class="card-info-2">
            <router-link to="/order/1" class="info-2-left">
                <p class="warehouse">{{ $t('my_sale') }}</p>
                <p class="warehouse-quantity">{{ userData.sale_log }}</p>
                <img class="warehouse-icon" :src="wareHouseImg" alt/>
            </router-link>
            <router-link class="info-2-right" to="/order/2">
                <p class="transaction">{{ $t('trade_record') }}</p>
                <p class="transaction-quantity">{{ userData.trade_log }}</p>
                <img class="transaction-icon" :src="transactionImg" alt/>
            </router-link>
        </div>
        <div class="card-info-3">
            <div class="three-column" @click="customerCare()">
                <img class="pre-icon" :src="personImg" alt/>
                <p class="text-card-info-3">{{ $t('service_center') }}</p>
                <img class="icon-arrow-right" :src="whiteArrowRightImg" alt/>
            </div>
            <van-divider class="divider"/>
            <router-link class="three-column" to="/rule">
                <img class="pre-icon" :src="checkListImg" alt/>
                <p class="text-card-info-3">{{ $t('platform_protocol') }}</p>
                <img class="icon-arrow-right" :src="whiteArrowRightImg" alt/>
            </router-link>
            <van-divider class="divider"/>
            <div class="three-column">
                <img class="pre-icon" :src="infoImg" alt/>
                <p class="text-card-info-3">{{ $t('version') }}</p>
                <p class="version-text">{{ userData.version }}</p>
            </div>
        </div>
        <div class="card-info-4" @click="logout()">
            <img class="pre-icon" :src="onOffImg" alt/>
            <p class="logout-text">{{ $t('log_out') }}</p>
        </div>
        <FooterView/>
    </div>
    <!--withdraw method pop up-->
    <van-popup class="method-popup" round closeable position="bottom" @click-overlay="cancelBTN" v-model:show="show">
        <p class="withdrawal-title">{{ $t('withdrawal_method') }}</p>
        <div class="withdrawal-popup">
            <van-loading color="#0094ff" v-show="loading"/>
            <van-button v-for="(data) in data" :key="data" class="bind-unbind-btn"
                        @click="myTransfer(data.is_bank, data.bank_card, data.transfer_method_id, data.user_bank_id, data.p_platform)"
                        v-show="loading === false">
                <span>
                    <img :src="cardIcon" alt/>
                    <p class="transfer-type">{{ data.name }}</p>
                </span>
                <van-tag class="tag" :color="data.is_bank === 0 ? '#DFE2E9' : '#101519'" size="small">
                    <span v-show="data.is_bank == 1">({{ $t('bound') }})</span>
                    <span v-show="data.is_bank == 0">({{ $t('unbind') }})</span>
                </van-tag>
            </van-button>
        </div>
    </van-popup>
    <!--do withdraw popup-->
    <van-popup class="method-popup" round closeable position="bottom" @click-overlay="cancelBTN"
               @click-close-icon="cancelBTN"
               v-model:show="showNotBankMethod">
        <div class="pay-popup">
            <p>{{ $t('transfer_bank_account') }}</p>
            <p class="label-bank-card">{{ $t('bank_card') }}:</p>
            <van-field class="field-bank-card" center v-model="cardNumber" :placeholder="$t('unbind')" readonly>
            </van-field>
            <p class="label-bank-card">
                {{ $t('current_withdrawable_balance') }}:
                <a> {{ userData.coin }} </a>
                {{ $t('coin') }}
            </p>
            <van-field class="field-bank-card" center type="digit" v-model="balanceInput" :placeholder="placeholder"/>
            <p class="red-text">
                {{ $t('attention') }}: {{ $t('maximum_withdrawal_day') }} {{ Number(userData.max_withdraw_limit) }}
                {{ $t('times') }},{{ $t('withdraw_remaining_amount_today') }} {{ withdrawal_amount_left_day }}
                {{ $t('coin') }};
                {{ $t('arrival_time') }}。
            </p>
            <van-button
                class="back-confirm-btn"
                @click="goldWithdrawal"
                :style="{backgroundColor: (Number(balanceInput) < userData.min_withdraw || Number(balanceInput) > userData.coin) ? '#AEAEAF' : '#D93D23'}"
                :loading="loadingWithdraw"
                :disabled="Number(balanceInput) < userData.min_withdraw || Number(balanceInput) > userData.coin"
            >
                {{ $t('confirm') }}
            </van-button>
        </div>
    </van-popup>
    <!--bind bank-->
    <van-popup class="method-popup" round closeable position="bottom" @click-overlay="cancelBTN"
               @click-close-icon="cancelBTN"
               v-model:show="modifyPayMethod">
        <div class="bind-bank">
            <p>{{ $t('bind') }}{{ $t('bank_card') }}</p>
            <p class="label-field">{{ $t('name') }}:</p>
            <van-field class="input-field" center v-model="payName" :placeholder="$t('nickname')"/>
            <p class="label-field">{{ $t('account_number') }}:</p>
            <van-field class="input-field" type="digit" center v-model="card" :placeholder="$t('bank_account')"/>
            <p class="label-field">{{ $t('bank') }}:</p>
            <div class="bank-card-number" @click="getSupportBank">
                <p>{{ (selectedBank == '') ? $t('choose_bank') : selectedBank }}</p>
                <img :src="whiteArrowRightImg" alt/>
            </div>
            <van-button
                class="back-confirm-btn"
                @click="bindBankCard"
                :loading="loadingBindBankCard"
            >
                {{ $t('confirm') }}
            </van-button>
        </div>
    </van-popup>
    <!--Redeem Naughty Stone Reminder-->
    <van-popup class="method-popup" round closeable position="bottom" @click-overlay="cancelBTN"
               v-model:show="showReminderExchange">
        <p class="exchange-reminder-title">{{ $t('money_to_stone_coin') }}</p>
        <p class="exchange-reminder-subtitle">
            {{ $t('irreversible') }}
        </p>
        <div class="warning-exchange">
            <p class="warning-text-title">{{ $t('remind') }}:</p>
            <p class="warning-text">{{ $t('irreversible_msg') }}</p>
        </div>
        <van-button class="back-confirm-btn" style="margin-top: 2vh;" @click="confirmBTN(3)">
            {{ $t('confirm') }}
        </van-button>
    </van-popup>
    <!--exchange naughty stone-->
    <van-popup class="method-popup" round closeable position="bottom" @click-overlay="cancelBTN"
               @click-close-icon="cancelBTN"
               v-model:show="showExchangePopup">
        <p class="exchange-title">{{ $t('exchange_stone_coin') }}</p>
        <div class="warning-exchange">
            <p class="warning-text-title">{{ $t('explain') }}:</p>
            <ul>
                <li class="list-explain"><a>1.</a> {{ $t('explain_1') }}</li>
                <li class="list-explain"><a>2.</a> {{ $t('explain_2') }}</li>
                <li class="list-explain"><a>3.</a> {{ $t('explain_3') }}</li>
                <li class="list-explain"><a>4.</a> {{ $t('explain_4') }}</li>
            </ul>
        </div>
        <div class="exchange-input">
            <p class="exchange-input-title">
                {{ $t('current_exchange_quantity') }}
                <a>
                    {{ userData.coin_to_stone ? Math.floor(Number(userData.coin / userData.coin_to_stone)) : 0 }}
                </a>
            </p>
            <van-field
                v-model="exchange_price"
                type="digit"
                class="field-input"
                center
                clearable
                :placeholder="$t('please_enter_stone_coin_quantity')"
            />
            <p class="exchange-info">{{ $t('minimum') }} {{ userData.min_coin_transfer }}</p>
            <p class="exchange-info">1 {{ $t('coin') }} = {{ exchangeRateStone }} {{ $t('stone_coin') }}</p>
        </div>
        <p class="quantity-exchange">
            {{ $t('exchange_quantity_day') }}
            <b>{{ Number(userData.coin_to_stone_limit) }}</b>
            {{ $t('times') }}，{{ $t('exchanged_current') }}
            <b>{{ userData.stone_number_today }}/{{ userData.coin_to_stone_limit }}</b>
            {{ $t('times') }}
        </p>
        <van-button
            class="back-confirm-btn"
            :style=" {backgroundColor: (Number(exchange_price) < userData.min_coin_transfer || Number(exchange_price) > Math.floor(Number(userData.coin / userData.coin_to_stone)) || userData.stone_number_today >= userData.coin_to_stone_limit) ? '#AEAEAF' : '#D93D23'}"
            @click="exchange_coin"
            :loading="loadingExchangeCoin"
            :disabled="Number(exchange_price) < userData.min_coin_transfer || Number(exchange_price) > Math.floor(Number(userData.coin / userData.coin_to_stone)) || userData.stone_number_today >= userData.coin_to_stone_limit"
        >
            {{ $t('confirm') }}
        </van-button>
    </van-popup>
    <!--customer service-->
    <van-popup class="method-popup" round closeable position="bottom" @click-overlay="cancelBTN"
               v-model:show="showCustomerService">
        <p class="customer-title">{{ $t('service_center') }}</p>
        <div class="service_care">
            <p class="service_title">{{ $t('service_work_time') }}:</p>
            <p class="working_time">{{ serviceTime }}</p>
            <van-button v-show="VIPService.url" class="customer-service-vip" :loading="loading"
                        :loading-text="$t('loading')"
                        @click="contactService(VIPService.url)">
                <div class="btn-service-vip">
                    <img :src="serviceVipImg" alt/>
                    <p class="text-btn">{{ VIPService.name ? VIPService.name : $t('service_vip') }}</p>
                </div>
            </van-button>
            <van-button v-show="customerService.url" class="general-customer-service" :loading="loading"
                        :loading-text="$t('loading')"
                        @click="contactService(customerService.url)">
                <div class="btn-service-general">
                    <img :src="generalServiceImg" alt/>
                    <p class="text-btn">{{ customerService.name ? customerService.name : $t('service_common') }}</p>
                </div>
            </van-button>
        </div>
    </van-popup>
    <!--choose bank-->
    <van-popup class="method-popup" v-model:show="chooseBank" closeable round position="bottom">
        <div class="choose-bank-area">
            <h1>{{ $t('choose_bank') }}</h1>
            <van-list
                v-model:loading="bankLoading"
                :finished="finishedBankList"
                :loading-text="$t('loading')"
                :finished-text="$t('load_finished')"
                :error-text="$t('load_error')"
            >
                <van-cell v-for="item in bankList" :key="item.id" :title="item.name"
                          @click="pickedBank(item.id, item.name)"/>
            </van-list>
        </div>
    </van-popup>
</template>
<script>
import {ref} from 'vue'
import {NavBar, Toast} from "vant";
import FooterView from "@/layout/footer/FooterView";
import {
    user,
    customerService,
    exchangeCoin,
    getSupportBank,
    bindBankCard,
    goldWithdrawal,
    logout,
    withdrawalMethod
} from '@/api/user';
import phoneImg from '@/assets/img_icon/phone.png';
import redArrowRightImg from '@/assets/img_icon/red-arrow-right.png';
import editImg from '@/assets/img_icon/edit.png';
import coinImg from '@/assets/img_icon/coin.png';
import informationImg from '@/assets/img_icon/information.png';
import wareHouseImg from '@/assets/img_icon/warehouse.png';
import transactionImg from '@/assets/img_icon/Transaction.png';
import personImg from '@/assets/img_icon/person.png';
import whiteArrowRightImg from '@/assets/img_icon/white-arrow-right.png';
import checkListImg from '@/assets/img_icon/checklist.png';
import infoImg from '@/assets/img_icon/info.png';
import onOffImg from '@/assets/img_icon/on-off.png';
import serviceVipImg from '@/assets/img_icon/service-vip.png';
import generalServiceImg from '@/assets/img_icon/general-service.png';
import cardIcon from '@/assets/img_icon/card.png';

export default {
    name: "UserInfo",
    components: {
        [NavBar.name]: NavBar,
        FooterView
    },
    data() {
        return {
            phoneImg,
            redArrowRightImg,
            editImg,
            coinImg,
            informationImg,
            wareHouseImg,
            transactionImg,
            personImg,
            whiteArrowRightImg,
            checkListImg,
            infoImg,
            onOffImg,
            serviceVipImg,
            generalServiceImg,
            cardIcon,
            transferTypeObj: {
                1: this.$t('bank_transfer_method'),
            },
            exchangeRate: 1,
            currency: process.env.VUE_APP_CURRENCY_SYMBOL ? process.env.VUE_APP_CURRENCY_SYMBOL : '',
            exchangeRateStone: 1,
            version: '1.0.0',
            transferType: 1,
            withdrawal_amount_limit_day: 0,
            withdrawal_amount_minimum: 0,
            withdrawal_amount_left_day: 0,
            loadingExchangeCoin: false,
            loadingWithdraw: false,
            serviceTime: '',
            VIPService: {
                url: '',
                name: ''
            },
            switch_exchange: 0,
            switch_withdraw: 0,
            platform_id: 0,
        };
    },
    mounted() {
        this.onLoad();
    },
    setup() {

        const userData = ref([]);
        const fileList = ref([]);
        const test = ref(false);
        const bound = ref(true);
        const show = ref(false);
        const showBankMethod = ref(false);
        const showNotBankMethod = ref(false);
        const disabledConfirm = ref(true);
        const modifyPayMethod = ref(false);
        const cardNumber = ref('---- ---- ---- ----');
        const payNumber = ref('');
        const balance = ref('12345678');
        const balanceInput = ref('');
        const card = ref('');
        const payName = ref('');
        const isBank = ref(false);
        const selectedBank = ref('');
        const showReminderExchange = ref(false);
        const showExchangePopup = ref(false);
        const showCustomerService = ref(false);
        const loading = ref(false);
        const customerService = {
            url: '',
            name: ''
        };
        const isBound = ref(false);
        const chooseBank = ref(false);
        const finishedBankList = ref(false);
        const bankLoading = ref(false);
        const loadingBindBankCard = ref(false);
        const transfer_method_id = ref(0);
        const user_bank_id = ref(0);
        const data = ref([]);
        const pickedBankId = ref('');
        const cancelBTN = (value) => {
            balanceInput.value = '';
            selectedBank.value = '';
            exchange_price.value = '';
            if (value === 0) {
                showBankMethod.value = false;
            } else if (value === 1) {
                showNotBankMethod.value = false;
            } else if (value === 2) {
                modifyPayMethod.value = false;
            }
            customerService.value = null;
        };
        const exchange_price = ref('');
        const confirmBTN = (value) => {
            if (value === 0) {
                showBankMethod.value = false;
            } else if (value === 2) {
                modifyPayMethod.value = false;
            } else if (value === 3) {
                showExchangePopup.value = true;
            }
        };
        const loadingUserinfo = ref(false);
        const bankList = ref([]);

        return {
            userData,
            fileList,
            test,
            bound,
            show,
            showBankMethod,
            showNotBankMethod,
            disabledConfirm,
            cancelBTN,
            confirmBTN,
            cardNumber,
            balance,
            balanceInput,
            card,
            payNumber,
            isBank,
            modifyPayMethod,
            payName,
            data,
            selectedBank,
            showReminderExchange,
            showExchangePopup,
            loadingBindBankCard,
            exchange_price,
            showCustomerService,
            loading,
            customerService,
            loadingUserinfo,
            isBound,
            chooseBank,
            bankList,
            finishedBankList,
            bankLoading,
            pickedBankId,
            transfer_method_id,
            user_bank_id,
        }
    },
    methods: {
        onClickLeft() {
            this.$router.back();
        },
        myTransfer(is_bank, bank_card, transfer_method_id, user_bank_id, platform_id) {
            this.cardNumber = bank_card;
            this.transfer_method_id = transfer_method_id;
            this.user_bank_id = user_bank_id;
            this.platform_id = platform_id;
            if (is_bank === 0) {
                this.modifyPayMethod = true;
            } else {
                this.showNotBankMethod = true;
            }
        },
        goTOEdit(avatar, nickname) {

            let param = {
                avatar: avatar,
                nickname: nickname
            }
            this.$router.push(
                {
                    path: '/editinfo',
                    query: {
                        obj: JSON.stringify(param)
                    }
                }
            );
        },
        goToOrder(tab) {
            console.log(tab);
        },
        bind() {
            this.$router.push({path: '/login'})
        },
        onLoad() {
            this.loadingUserinfo = true;
            user().then(res => {
                if (res.msg === 'success' || res.data !== [] || res.code === 200) {
                    this.isBound = true;
                    this.userData = res.data;
                    this.loadingUserinfo = false;
                    this.balance = this.userData.coin;
                    this.serviceTime = res.data.service_time;
                    this.exchangeRate = this.userData.withdraw;
                    this.exchangeRateStone = this.userData.coin_to_stone;
                    this.withdrawal_amount_limit_day = this.userData.max_withdraw_amount;
                    this.withdrawal_amount_minimum = this.userData.min_withdraw;
                    this.withdrawal_amount_left_day = Number(this.userData.max_withdraw_amount) - Number(this.userData.withdraw_money_today);
                    this.switch_exchange = res.data.switch_exchange;
                    this.switch_withdraw = res.data.switch_withdraw;
                }
            }).catch(e => {
                console.log(e);
                this.loadingUserinfo = false;
            })
        },
        exchange_coin() {
            this.loadingExchangeCoin = true;
            let param = {
                stone: this.exchange_price
            };
            exchangeCoin(param).then(res => {
                if (res.msg === 'success') {
                    Toast({
                        message: this.$t('success'),
                        type: 'success',
                        forbidClick: true,
                    });
                    this.loadingExchangeCoin = false;
                    this.showReminderExchange = false;
                    this.showExchangePopup = false;
                    setTimeout(() => {
                        this.$router.go(this.$router.currentRoute);
                    }, 2000);
                }
            }).catch(e => {
                console.log(e);
                this.showReminderExchange = false;
                this.showExchangePopup = false;
                this.loadingExchangeCoin = false;
            })
        },
        getSupportBank() {
            this.chooseBank = true;
            this.bankLoading = true;
            let param = {
                p_platform: this.platform_id
            };
            console.log('param', param);
            getSupportBank(param).then(res => {
                this.bankLoading = false;
                this.bankList = res.data;
                this.finishedBankList = true;
            })
        },
        pickedBank(bankId, bankName) {
            this.pickedBankId = bankId;
            this.selectedBank = bankName;
            this.chooseBank = false;
        },
        bindBankCard() {
            let param = {
                name: this.payName,
                card: this.card,
                bank_id: this.pickedBankId,
            };
            bindBankCard(param).then(res => {
                this.loadingBindBankCard = true;
                if (res.msg === 'success') {
                    Toast({
                        message: this.$t('success'),
                        type: 'success',
                        forbidClick: true,
                    });
                    this.loadingBindBankCard = false;
                    setTimeout(() => {
                        this.cardNumber = res.data.card;
                        this.user_bank_id = res.data.user_bank_id;
                        this.show = false;
                        this.modifyPayMethod = false;
                        this.showNotBankMethod = true;
                    }, 2000);
                }
            }).catch((e) => {
                console.log(e);
                this.loadingBindBankCard = false;
            })
        },
        showWithdrawalMethod() {
            this.show = true;
            this.loading = true;
            withdrawalMethod().then(res => {
                this.loading = false;
                if (res.msg === 'success' || res.data !== [] || res.code === 200) {
                    this.data = res.data;
                }
            }).catch((e) => {
                console.log(e);
                this.loading = false;
            })
        },
        goldWithdrawal() {
            this.loadingWithdraw = true;
            let param = {
                coin: this.balanceInput,
                transfer_method_id: this.transfer_method_id,
                user_bank_id: this.user_bank_id,
            };
            goldWithdrawal(param).then(res => {
                this.loadingWithdraw = true;
                if (res.msg === 'success') {
                    Toast({
                        message: this.$t('success'),
                        type: 'success',
                        forbidClick: true,
                    });
                    this.loadingWithdraw = false;
                    setTimeout(() => {
                        this.$router.go(this.$router.currentRoute);
                    }, 2000);
                }
            }).catch((e) => {
                console.log(e);
                this.loadingWithdraw = false;
            })
        },
        customerCare() {
            this.showCustomerService = true;
            this.loading = true;
            customerService().then(res => {
                if (res.msg === 'success') {
                    this.loading = false
                    for (let i = 0; i < res.data.length; i++) {
                        if (2 === res.data[i].type) {
                            this.customerService = res.data[i];
                        } else if (1 === res.data[i].type) {
                            this.VIPService = res.data[i];
                        }
                    }
                }
            }).catch((error) => {
                console.log('error', error);
            });
        },
        contactService(value) {
            window.open(value, "_blank");
            // for (let i = 0; i <= this.contactService.length; i++) {
            //     if (value === this.customerService[i].type) {
            //         window.open(this.customerService[i].url, "_blank");
            //     }
            // }
        },
        logout() {
            logout();
            this.$store.dispatch('user/logout');
            this.$router.push(`/login`);
        },
    },
    computed: {
        placeholder() {
            const str = this.$t('please_enter_amount') + ', '
                + this.$t('minimum') + ' ' + this.withdrawal_amount_minimum + ' ' + this.$t('coin') + ', '
                + '1 ' + this.$t('coin') + ' = ' + this.exchangeRate + ' ' + this.currency;
            return str;
        }
    }
}
</script>

<style lang="scss" src="@/styles/userinfo.scss" scoped>
</style>

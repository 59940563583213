<template>
    <BackNavBar :title="$t('platform_rules')"></BackNavBar>
    <div class="user_agreement" style="min-height: 91vh">
        <div v-html="htmlData1"></div>
        <div v-html="htmlData2" style="margin-top: 2rem"></div>
    </div>
    <van-loading class="loading-box" type="spinner" v-show="loading"/>
</template>

<script>
import {NavBar} from "vant";
import {userAgreement} from "@/api/user";
import BackNavBar from "@/components/BackNavBar";

export default {
    name: "ProtocolView",
    components: {
        BackNavBar,
        [NavBar.name]: NavBar,
    },
    data() {
        return {
            htmlData1: '',
            htmlData2: '',
            loading: false,
        }
    },
    mounted() {
        this.loading = true;
        const param = {
            type: 0
        };
        userAgreement(param).then(res => {
            this.htmlData1 = res.data[1];
            this.htmlData2 = res.data[1];
            this.loading = false;
        }).catch((e) => {
            this.loading = false;
            console.log(e);
        })
    },
    methods: {
        onClickLeft(a) {
            this.$router.back();
            console.log(a)
        }
    }
}

</script>
<style lang="scss" src="@/styles/useragreement.scss" scoped></style>
<template>
    <div class="edit-page">
        <div class="top-item">
            <img :src="blackArrowLeftImg" @click="onClickLeft" alt/>
            <p>{{ $t('modify_personal_information') }}</p>
        </div>
        <div class="form-upload">
            <img v-show="avatar != ''" :src="avatar" @click="showOverlay = true" alt/>
            <img v-show="avatar == ''" :src="defaultUserImg" @click="showOverlay = true" alt/>
            <div class="btn">
                <van-uploader :after-read="afterRead" :max-size="500 * 1024">
                    <van-button class="upload-btn">
                        <img :src="uploadImg" alt/>
                    </van-button>
                </van-uploader>
            </div>
        </div>
        <van-field class="nickname-field"
                   v-model="userObj.nickname"
                   :placeholder="$t('nickname')"
                   @update:model-value="editName"
                   center clearable
        />
        <div class="btn-save">
            <van-button
                class="submit-btn"
                block native-type="submit"
                @click="onSubmit"
                :loading="loading"
            >
                {{ $t('save') }}
            </van-button>
        </div>

        <van-overlay :show="showOverlay" @click="showOverlay = false">
            <div class="wrapper">
                <img v-show="avatar !== ''" :src="avatar" alt/>
                <img v-show="avatar === ''" :src="defaultUserImg" alt/>
            </div>
        </van-overlay>
    </div>
</template>
<script>
import {ref} from 'vue'
import {NavBar, Toast} from "vant";
import {bindUser, uploadImage} from "@/api/user";
import blackArrowLeftImg from '@/assets/img_icon/black-arrow-left.png';
import defaultUserImg from '@/assets/img_icon/default-user.png';
import uploadImg from '@/assets/img_icon/uploader.png';

export default {
    name: "EditInfo",
    components: {
        [NavBar.name]: NavBar,
    },
    data () {
        return {
            blackArrowLeftImg, defaultUserImg, uploadImg,
            avatar: '',
            nickname: '',
            loading: false,
        };
    },
    setup() {
        const fileList = ref([]);
        const profilePic = ref('');
        const show = ref(false);
        const showOverlay = ref(false);
        const dataImg = ref('');
        const showPopup = () => {
            show.value = true;
        };
        const userObj = ref({});
        const image = ref('');
        return {
            profilePic,
            fileList,
            show,
            showPopup,
            showOverlay,
            dataImg,
            userObj,
            image
        };
    },
    created() {
        this.userObj = JSON.parse(this.$route.query.obj);
        this.nickname = this.userObj.nickname;
        this.avatar = this.userObj.avatar;
    },
    methods: {
        editName (value)  {
            this.nickname = value;
        },
        onClickLeft() {
            this.$router.back();
        },
        afterRead(fileImg) {
            let param = {
                file: fileImg.file
            };
            fileImg.status = 'uploading';
            fileImg.message = 'Uploading...';
            uploadImage(param).then(res => {
                if (res.data != '') {
                    fileImg.status = 'success';
                    fileImg.message = 'success';
                    this.avatar = fileImg.content;
                    this.dataImg = res.data;
                }
            }).catch((e) => {
                console.log(e);
                fileImg.status = 'failed';
                fileImg.message = 'Failed';
            })
        },
        onSubmit() {
            this.loading = true;
            let params = {
                nickname: this.nickname,
                avatar: this.dataImg ? this.dataImg : this.avatar,
            };
            bindUser(params).then(res => {
                if (res.msg === 'success' || res.data !== [] || res.code === 200) {
                    Toast({
                        message: this.$t('success'),
                        type: 'success',
                        forbidClick: true,
                    });
                    this.loading = false;
                    setTimeout(() => {
                        this.$router.push({path: '/userinfo'});
                    }, 2000);
                }
            }).catch((e) => {
                console.log(e);
                this.loading = false;
            })
        }
    }
}
</script>
<style lang="scss" src="@/styles/editInfo.scss" scoped></style>
<template>
    <van-tabbar class="footer-bar" v-model="active" route>
        <van-tabbar-item class="first-tap" to="/" icon="home-o">
            <template #icon="props">
                <img :src="props.active ? icon.indexActive : icon.indexInactive" class="bar-img"/>
                <span :style="{color : (props.active ? '#D93D23' : ''), fontSize: '0.625rem', display: 'block'}">{{ $t('index') }}</span>
            </template>
        </van-tabbar-item>
        <van-tabbar-item class="second-tap" to="/shangdai" icon="friends-o">
            <template #icon="props">
                <img :src="props.active ? icon.packageActive : icon.packageInactive" class="bar-img"/>
                <span :style="{color : (props.active ? '#D93D23' : ''), fontSize: '0.625rem', display: 'block'}">{{ $t('package') }}</span>
            </template>
        </van-tabbar-item>
        <van-tabbar-item class="third-tap"  to="/userinfo" icon="setting-o">
            <template #icon="props">
                <img :src="props.active ? icon.meActive : icon.meInactive" class="bar-img"/>
                <span :style="{color : (props.active ? '#D93D23' : ''), fontSize: '0.625rem', display: 'block'}">{{ $t('me') }}</span>
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
import {ref} from "vue";
import {Tabbar, TabbarItem} from 'vant';
import indexActive from '@/assets/img_icon/index_active_2x.png';
import indexInactive from '@/assets/img_icon/index_2x.png';
import packageActive from '@/assets/img_icon/package_active_2x.png';
import packageInactive from '@/assets/img_icon/package_2x.png';
import meActive from '@/assets/img_icon/me_active_2x.png';
import meInactive from '@/assets/img_icon/me_2x.png';

export default {
    name: "FooterView",
    components: {
        [Tabbar.name]: Tabbar,
        [TabbarItem.name]: TabbarItem,
    },
    setup() {
        const active = ref(0);
        const icon = {
            indexActive: indexActive,
            indexInactive: indexInactive,
            packageActive: packageActive,
            packageInactive: packageInactive,
            meActive: meActive,
            meInactive: meInactive,
        };
        return {
            active,
            icon
        }
    }
}
</script>

<style scoped>
.footer-bar {
    border-radius: 24px 24px 0 0;
    height: 4.8rem;
}
.first-tap {
    border-radius: 24px 0 0 0;
}
.second-tap-tap {
    border-radius: 0;
}
.third-tap {
    border-radius: 0 24px 0 0;
}
.bar-img {
    margin: 0 auto 0.3rem;
    height: 1.875rem;
}
</style>
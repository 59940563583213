import onlineReq from "../utils/onlineReq"

export function backpack(query){
    return onlineReq({
        url: '/index/user/pack',
        method: 'get',
        params: query
    })
}
export function packCategory(query){
    return onlineReq({
        url: '/index/user/pack_category',
        method: 'get',
        params: query
    })
}
export function sellGoods(query){
    return onlineReq({
        url: '/index/goods/sale',
        method: 'post',
        data: query
    })
}
export function getMagicBoxToken(query){
    return onlineReq({
        url: '/index/user/access_token',
        method: 'post',
        data: query
    })
}

<template>
    <div class="detailPage">
        <div class="slide-container">
            <div class="top-item">
                <img :src="blackArrowLeftImg" @click="onClickLeft" alt/>
                <p>{{ $t('detail') }}</p>
<!--                <img :src="shareImg">-->
            </div>
            <van-swipe class="my-swipe" lazy-render>
                <van-swipe-item v-for="(item,index) in detail.goods" :key="index">
                    <div class="slide-item">
                        <van-swipe class="img" :autoplay="0"  indicator-color="#ffffff" v-show="item.img.length > 1">
                            <van-swipe-item v-for="(img,index) in item.img" :key="index">
                                <div class="image">
                                    <img class="slide-img-2" :src="img" @click="expandImage(img)"/>
                                </div>
                            </van-swipe-item>
                        </van-swipe>
                        <div class="img">
                            <img class="slide-img" :src="item.img" @click="expandImage(item.img)" v-show="item.img.length === 1"/>
                        </div>
                            <div class="description-tag">
                            <span class="description-left">{{ item.name }}</span>
                            <span class="description-right">× {{ item.num }}</span>
                        </div>
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="description">
            <div class="top-description">
                <span>{{ $t('finally_price') }}:</span>
                <span>{{ $t('goods_quantity') }}<b> × {{ goodsNum }}</b></span>
            </div>
            <p class="price">
                <span>{{ currency }}</span>
                {{ detail.price }}
            </p>
            <div class="item-info">
                <span class="text-tag">
                    <span class="tag">
                        <van-tag class="buy-tag" color="#FFDD52" size="large" v-show="detail.type === 2">
                            <p>{{ $t('buy') }}</p>
                        </van-tag>
                        <van-tag class="sale-tag" color="#101519" size="large" v-show="detail.type === 1">
                            <p>{{ $t('sell') }}</p>
                        </van-tag>
                    </span>
                        {{ detail.title }}
                </span>
            </div>
        </div>
        <div class="card">
            <van-skeleton avatar :row="1" :loading="loading">
                <img class="card-profile"
                     :src="avatar"
                     alt/>
            </van-skeleton>
            <div class="card-info">
                <p class="card-name">{{ detail.nickname }}</p>
                <p class="card-number">{{ detail.phone }}</p>
            </div>
        </div>
        <div class="date-time">
            <span class="date-time-box">
                <p class="date-time-title">{{ $t('trade_deadline') }}</p>
                <p class="date-time-content">{{ detail.end_time }}</p>
            </span>
            <span class="date-time-box">
                <p class="date-time-title">{{ $t('trade_post_time') }}</p>
                <p class="date-time-content">{{ detail.created_at }}</p>
            </span>
        </div>
        <van-divider class="divider"/>
        <div class="buy-cancel-btn">
            <van-button class="btn"
                        @click="showBuy = true"
                        v-show="showBtn === true && isBuy === true"
            >
                {{ $t('buy_now') }}
            </van-button>
            <van-button class="btn"
                        @click="goodsCancel(detail.p_id)"
                        :loading="loadingCancel"
                        v-show="showBtn === true && isBuy === false"
            >
                {{ $t('cancel_trade') }}
            </van-button>
        </div>
    </div>
    <!--pop up to comfirm buy item-->
    <van-popup class="popup" position="bottom"
               v-model:show="showBuy"
               closeable round>
        <p class="popup-title">{{ $t('buy_confirm') }}</p>
        <img class="popup-slide-img" :src="slide" alt/>
        <p class="pop-up-subtitle">{{ $t('recharge_success_msg') }}</p>
        <div class="card-cancel">
            <p class="card-cancel-text">
                {{ $t('trade_close_msg') }} 1 {{ $t('hour') }}
            </p>
        </div>
        <p class="confirmation-text">{{ $t('buy_confirm') }}</p>
        <p class="price">
            <span>{{ currency }}</span>
            {{ detail.price }}
        </p>
        <div class="checkbox">
            <van-checkbox
                v-model="isChecked"
                icon-size="1rem"
                direction="horizontal"
                :checked-color="checkedColor"
            >
                {{ $t('agree') }}
                <router-link to="/siterules" style="">( {{ $t('user_service_agreement') }} )</router-link>
            </van-checkbox>
        </div>
        <div class="buy-btn">
            <van-button
                class="btn-buy"
                :disabled="(isChecked === false)"
                :style="{backgroundColor: (isChecked === false) ? '#AEAEAF' :'#D93D23'}"
                :loading="btnLoading"
                @click="goodsBuy()"
            >
                {{ $t('coin_payment') }}
            </van-button>
        </div>
    </van-popup>
    <!--overlay view full img-->
    <van-overlay class-name="overlay-img" :show="showImg === true" @click="showImg = false">
        <div class="wrapper-Image">
            <img :src="clickedImg" alt/>
        </div>
    </van-overlay>
    <van-loading class="loading-box" type="spinner" v-show="loading"/>
</template>

<script>
import {NavBar, Toast, Dialog } from "vant";
import {ref} from 'vue'
import {goodsDetail, buy, cancel} from "@/api";
import blackArrowLeftImg from '@/assets/img_icon/black-arrow-left.png';
import shareImg from '@/assets/img_icon/share-icon.png';
import slide from '@/assets/img_icon/slide.png';

export default {
    name: "DetailItem",
    components: {
        [NavBar.name]: NavBar,
        [Dialog.Component.name]: Dialog.Component,
    },
    data() {
        return {
            blackArrowLeftImg,
            shareImg,
            slide,
            loading: false,
            btnLoading: false,
            loadingCancel: false,
            detail: {},
            showImg: false,
            clickedImg: '',
            goodsNum: 0,
            avatar: '',
            checkedColor: process.env.VUE_APP_CHECKEDBGCOLOR ? process.env.VUE_APP_CHECKEDBGCOLOR : '',
        };
    },
    created() {
        this.getDetail(this.$route.params.id);
    },
    setup() {
        const showBtn = ref(false);
        const showBuy = ref(false);
        const isChecked = ref(true);
        const isBuy = ref(true);
        const isBuyTag = ref(true);
        const currency = process.env.VUE_APP_CURRENCY_SYMBOL ? process.env.VUE_APP_CURRENCY_SYMBOL : '';

        return {
            showBtn,
            currency,
            showBuy,
            isBuy,
            isBuyTag,
            isChecked,
        };
    },
    methods: {
        expandImage (image) {
            this.showImg = true;
            this.clickedImg = image;
        },
        getDetail (pId) {
            this.loading = true;
            let param = {
                p_id: pId,
            };
            goodsDetail(param).then(res => {
                if (res.data) {
                    this.detail = res.data;
                    this.avatar = this.detail.avatar;
                    this.goodsNum = this.detail.goods.length;
                    if (Number(this.detail.status) !== 2) {
                        this.showBtn = true;
                    }
                    // 从我的订单点进来 type = 2, 从其他地方点进来 type = 1
                    this.isBuy = !(Number(this.detail.status) === 1 && this.detail.is_self);
                    this.loading = false;
                }
            }).catch((error) => {
                console.log('error', error);
                this.loading = false;
            });
        },
        onClickLeft() {
            this.$router.back();
        },
        goodsBuy() {
            this.btnLoading = true;
            let data = {
                id: this.$route.params.id,
            };
            buy(data).then(res => {
                if (res.msg === 'success') {
                    Toast({
                        message: this.$t('success'),
                        type: 'success',
                        forbidClick: true,
                    });
                    this.btnLoading = false;
                    setTimeout(() => {
                        this.onClickLeft();
                    }, 2000);
                }
            }).catch((error) => {
                console.log('error', error);
                this.btnLoading = false;
            });
        },
        goodsCancel (pId) {
            this.loadingCancel = true;
            Dialog.confirm({
                title: this.$t('confirm'),
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel'),
                message:
                    this.$t('confirm') + this.$t('cancel_trade') + ' ?',
            })
            .then(() => {
                // on confirm
                let param = {
                    id: pId,
                };
                cancel(param).then(res => {
                    if (res.msg === 'success') {
                        Toast({
                            message: this.$t('success'),
                            type: 'success',
                            forbidClick: true,
                        });
                        this.loadingCancel = false;
                        setTimeout(() => {
                            this.onClickLeft();
                        }, 2000);
                    }
                }).catch((error) => {
                    this.loadingCancel = false;
                    console.log('error', error);
                });
            })
            .catch(() => {
                this.loadingCancel = false;
            });
        },
    }
}
</script>

<style lang="scss">
.detailPage .van-swipe__indicators {
    bottom: 4vh;
}
</style>
<style lang="scss" src="@/styles/detailItem.scss" scoped></style>
<template>
    <van-nav-bar
        class="back-nav-bar"
        :title="title"
        left-arrow
        @click-left="onClickLeft"
    />
</template>

<script>
import {NavBar} from "vant";

export default {
    name: "BackNavBar",
    components: {
        [NavBar.name]: NavBar,
    },
    props: {
        title: String,
    },
    methods: {
        onClickLeft() {
            this.$router.back();
        }
    }
}
</script>

<style lang="scss">
    .back-nav-bar {
        background-color: #EBF0F4;
        font-size: 1.56rem;

        .van-icon {
            font-size: 1.56rem;
        }
    }
</style>
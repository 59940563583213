import onlineReq from "../utils/onlineReq"

export function accountDetail(query){
    return onlineReq({
        url: '/api/account/detail',
        method: 'get',
        params: query
    })
}

export function login(query) {
    return onlineReq({
        url: '/index/do_login',
        method: 'post',
        data: query
    })
}

export function thirdLogin(data) {
    return onlineReq({
        url: '/index/login',
        method: 'post',
        data: data
    })
}
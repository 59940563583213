<template>
    <BackNavBar :title="$t('about_stone_coin')"></BackNavBar>
    <div class="about-naughty-stone" >
        <div class="warning-exchange">
            <div class="user_agreement" v-html="htmlData"></div>
            <van-loading class="loading-box" type="spinner" v-show="loading"/>
        </div>
        <div class="magic-btn" @click="magicBtn">
            <img class="magic-icon" :src="magicBox">
            <div class="magic-text">
                <p class="bold-text">{{ $t('go_to_magic_box_title') }}</p>
                <p class="sub-text">{{ $t('go_to_magic_box_content') }}</p>
            </div>
            <img class="purple-arrow-right" :src="purpleArrow" alt/>
        </div>
    </div>
    <van-loading class="loading-box" type="spinner" color="#000000" size="3rem" v-show="loadingJump"/>
</template>

<script>
import { NavBar } from "vant";
import magicBox from '@/assets/img_icon/magic_box.png';
import purpleArrow from '@/assets/img_icon/purple_arrow_right.png';
import BackNavBar from "@/components/BackNavBar";
import {userAgreement} from "@/api/user";
import {getMagicBoxToken} from "@/api/backpack";

export default {
    name: "AboutNaughtyStoneView",
    components:{
        BackNavBar,
        [NavBar.name]:NavBar,
    },
    data () {
        return {
            htmlData: '',
            loading: false,
            magicBox,
            purpleArrow,
            loadingJump: false,
            magicBoxUrl: process.env.VUE_APP_MAGIC_BOX_URL ? process.env.VUE_APP_MAGIC_BOX_URL : '',
        };
    },
    mounted() {
        this.loading = true;
        const param = {
            type: 3
        };
        userAgreement(param).then(res => {
            this.htmlData = res.data;
            this.loading = false;
        }).catch((e) => {
            this.loading = false;
            console.log(e);
        })
    },
    methods:{
        magicBtn () {
            this.loadingJump = true;
            getMagicBoxToken().then(res => {
                if (res.data) {
                    window.location.href = this.magicBoxUrl + res.data.access_token;
                }
                this.loadingJump = false;
            }).catch((error) => {
                console.log('error', error);
                this.loadingJump = false;
            });
        },
        onClickLeft(a){
            this.$router.back();
            console.log(a)
        },
    }
}

</script>
<style lang="scss">
    .warning-exchange .van-loading__spinner--spinner {
        left: calc((100vw - 30px) / 2 - 14px) !important;
    }
</style>
<style lang="scss" src='../styles/aboutNaughtyStone.scss' scoped></style>

import onlineReq from "../utils/onlineReq"

export function logout(){
    return onlineReq({
        url: '/index/logout',
        method: 'post'
    })
}

export function user(query){
    return onlineReq({
        url: '/index/user/info',
        method: 'get',
        params: query
    })
}
export function userAgreement(query) {
    return onlineReq({
        url: '/index/config/user_agreement',
        method: 'get',
        params: query
    })
}
export function customerService() {
    return onlineReq({
        url: '/index/config/service',
        method: 'get',
    })
}
export function order(query) {
    return onlineReq({
        url: '/index/goods/order',
        method: 'get',
        params: query
    })
}
export function uploadImage(query) {
    return onlineReq({
        url: '/index/upload/image',
        method: 'post',
        data:query,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export function bindUser(query) {
    return onlineReq({
        url: 'index/user/bind',
        method: 'post',
        data: query
    })
}
export function billingRecord(query) {
    return onlineReq({
        url: '/index/user/account_record',
        method: 'get',
        params: query
    })
}
export function withdrawalMethod() {
    return onlineReq({
        url: '/index/user/transfer_method',
        method: 'get'
    })
}
export function exchangeCoin(query) {
    return onlineReq({
        url: '/index/account/stone',
        method: 'post',
        data: query

    })
}
export function getSupportBank(query) {
    return onlineReq({
        url: '/index/bank/index',
        method: 'get',
        params: query
    })
}
export function bindBankCard(query) {
    return onlineReq({
        url: '/index/bank/bind',
        method: 'post',
        data: query
    })
}
export function bindingCard(query) {
    return onlineReq({
        url: '/index/bank/user',
        method: 'get',
        params: query
    })
}
export function goldWithdrawal(query) {
    return onlineReq({
        url: '/index/account/money',
        method: 'post',
        data: query
    })
}
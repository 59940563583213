import axios from "axios";
import { Notify } from 'vant';
import store from '@/store';
import {getToken, tokenKey} from '@/utils/auth';
import { ErrorMsg } from '@/const/const'
import i18n from '@/lang/index';

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
})

service.interceptors.request.use(
    config => {
        if (store.getters.userToken) {
            const key = tokenKey()
            config.headers = config.headers ?? {}
            config.headers[key] = getToken()
        } else {
            console.log('Please log in');
        }
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 200) {
            if (res.code === 401) {
                Notify(i18n.global.t('please_login_again'));
                return ;
            } else {
                const msg = res.msg;
                let errorMsg = '';
                if (res.msg.indexOf('_') !== -1 && Object.prototype.hasOwnProperty.call(ErrorMsg, msg.split('_')[1])) {
                    errorMsg = ErrorMsg[msg.split('_')[1]];
                    errorMsg = msg.split('_')[2] ? errorMsg + msg.split('_')[2] : errorMsg;
                }
                Notify(errorMsg || msg || 'Error');
            }

            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        return Promise.reject(error)
    }
)
export default service
import onlineReq from "../utils/onlineReq"

export function index(query){
    return onlineReq({
        url: '/index/banner/advert',
        method: 'get',
        params: query
    })
}

export function hotSearch(query){
    return onlineReq({
        url: '/index/goods/hot',
        method: 'get',
        params: query
    })
}

export function category(query){
    return onlineReq({
        url: '/index/goods/category',
        method: 'get',
        params: query
    })
}

export function categoryGoods(query){
    return onlineReq({
        url: '/index/goods/index',
        method: 'get',
        params: query
    })
}

export function goodsDetail(query){
    return onlineReq({
        url: '/index/goods/detail',
        method: 'get',
        params: query
    })
}

export function rechargeMethod(query){
    return onlineReq({
        url: '/index/config/pay',
        method: 'get',
        params: query
    })
}

export function recharge(data){
    return onlineReq({
        url: '/index/pay/order',
        method: 'post',
        data: data
    })
}

export function buy(data){
    return onlineReq({
        url: '/index/goods/buy',
        method: 'post',
        data: data
    })
}

export function cancel(data){
    return onlineReq({
        url: '/index/goods/cancel',
        method: 'post',
        data: data
    })
}

export function getNotice(){
    return onlineReq({
        url: '/index/banner/run_horse',
        method: 'get',
    })
}

import {createI18n} from 'vue-i18n'
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhLocale from './ZH-CN';
import viVn from 'vant/es/locale/lang/vi-VN';
import vnLocale from './VI-VN'
import enUS from 'vant/es/locale/lang/en-US';
import enLocale from './EN-US'
import khLocale from './KH-KM'

const messages = {
    'zh_cn': {
        ...zhCN,
        ...zhLocale
    },
    'vi_vn': {
        ...viVn,
        ...vnLocale
    },
    'en_us': {
        ...enUS,
        ...enLocale
    },
    'kh_km': {
        ...khLocale
    },
}

const i18n = createI18n({
    locale: process.env.VUE_APP_LANGUAGE ? process.env.VUE_APP_LANGUAGE : 'vi_VN',
    messages
})
export default i18n
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vue3videoPlay from "vue3-video-play";
import "vue3-video-play/dist/style.css";
import {
    Button,
    Form,
    Field,
    Cell,
    CellGroup,
    Picker,
    Popup,
    NumberKeyboard,
    Checkbox,
    CheckboxGroup,
    RadioGroup,
    Radio,
    CountDown,
    Uploader,
    Card,
    Image as VanImage,
    Icon,
    DropdownMenu,
    DropdownItem,
    Tag,
    GridItem,
    Grid,
    Swipe,
    SwipeItem,
    Divider,
    List,
    Overlay,
    Skeleton,
    Loading
} from "vant";
import 'vant/lib/index.css';
import i18n from './lang/index';

require("./const/const");

if (Number(process.env.VUE_APP_MOCK)) {
    console.log('mock');
    require("../mock");
}
createApp(App)
    .use(store)
    .use(router)
    .use(Picker)
    .use(Button)
    .use(i18n)
    .use(Form)
    .use(Field)
    .use(CellGroup)
    .use(Cell)
    .use(Popup)
    .use(NumberKeyboard)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Radio)
    .use(RadioGroup)
    .use(CountDown)
    .use(Uploader)
    .use(Card)
    .use(VanImage)
    .use(Icon)
    .use(DropdownItem)
    .use(DropdownMenu)
    .use(Tag)
    .use(Grid)
    .use(GridItem)
    .use(Swipe)
    .use(SwipeItem)
    .use(Divider)
    .use(List)
    .use(Overlay)
    .use(Skeleton)
    .use(Loading)
    .use(vue3videoPlay)
    .mount('#app')

import Mock from 'mockjs'
import users from "./user";
const mocks= [
    ...users
]

for (const i of mocks) {
    Mock.mock(i.url, i.method, {
        mock: true,
        code: 200,
        msg: 'success',
        data: i.response
    })
}
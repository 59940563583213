<template>
    <BackNavBar :title="$t('buy_rules')"></BackNavBar>
    <div class="user_agreement" style="min-height: 91vh" v-html="htmlData"></div>
    <van-loading class="loading-box" type="spinner" v-show="loading"/>
</template>

<script>
import {NavBar} from "vant";
import {userAgreement} from "@/api/user";
import BackNavBar from "@/components/BackNavBar";

export default {
    name: "siteRules",
    components: {
        BackNavBar,
        [NavBar.name]: NavBar,
    },
    data() {
        return {
            htmlData: '',
            loading: false,
        }
    },
    methods: {
        onClickLeft(a) {
            this.$router.back();
            console.log(a)
        }
    },
    mounted() {
        this.loading = true;
        const param = {
            type: 1
        };
        userAgreement(param).then(res => {
            this.htmlData = res.data;
            this.loading = false;
        }).catch((e) => {
            this.loading = false;
            console.log(e);
        })
    },
}
</script>

const getters = {
    sidebar: state => state.app.sidebar,
    language: state => state.app.language,
    size: state => state.app.size,
    device: state => state.app.device,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    userToken: state => state.user.token,
    id: state => state.user.id,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    phone: state => state.user.phone,
    permission_routes: state => state.permission.routes,
    errorLogs: state => state.errorLog.logs
}
export default getters

<template>
    <van-overlay :show="!checkDevice">
        <div class="alert-text-box" @click.stop>
            <div class="block">
                <p class="alert-text">{{ $t('pc_alert') }}</p>
            </div>
        </div>
    </van-overlay>
    <div v-show="checkDevice">
        <router-view/>
    </div>
</template>
<script>
import {ref} from 'vue'
import {lang} from "@/const/const";
export default {
    data () {
        return {
            lang: lang,
        };
    },
    setup() {
        const checkDevice = ref(true);
        const defaultLangObj = ref({});
        return {
            checkDevice,
            defaultLangObj
        }
    },
    mounted() {
        if (localStorage.getItem('locale')) {
            if(localStorage.getItem('locale') === 'vn'){
                this.$i18n.locale = 'vi_vn';
            } else if (localStorage.getItem('locale') === 'en') {
                this.$i18n.locale = 'en_us';
            } else if (localStorage.getItem('locale') === 'kh') {
                this.$i18n.locale = 'kh_km';
            } else {
                this.$i18n.locale = 'zh_cn'
            }
        } else {
            this.$i18n.locale = process.env.VUE_APP_LANGUAGE
        }
        if (!this._isMobile()) {
            this.checkDevice = false;
        }
    },
    methods: {
        _isMobile() {
            return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        }
    }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #ebf0f4;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #ebf0f4;
    }
  }
}

.loading-box {
    position: fixed !important;
    width: 100% !important;
    top: 40% !important;
}

.alert-text-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.block {
    width: 300px;
    height: 120px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.alert-text {
    margin: 0;
}
</style>

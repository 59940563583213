const users = [
    {
        url: "/api/account/detail",
        method: "get",
        response: {
            'list|10': [
                {
                    'created_at': "@datetime('yyyy-MM-dd HH:mm:ss')",
                    'after_money': '@float(1000,100000,0,2)',
                    'type_name|1': ['出售商品','购买商品','转账银行卡','兑换淘气石','充值','代理充值','系统赠送'],
                    'change_money': '@float(-3000,5000,0,2)',
                }
            ]
        }
    }
]
export default users;